/*
 * @Author: DQL
 * @Date: 2020-03-09 17:00:31
 * @LastEditors: cgh
 * @LastEditTime: 2023-03-28 17:00:03
 * @Description: file content
 */
import axios from 'axios';
import config from '@/config';

const { commontCtx, authCtx } = config;
const { iffApi } = config.ctx;

// 登录认证
export const loginSys = (params) => { return axios.post(`${authCtx}/auth/security/login`, params).then(rsp => rsp); };
// sm4加密登录
export const loginSysSm4 = (params) => { return axios.post(`${authCtx}/auth/security/login?encrypt=sm4`, params).then(rsp => rsp); };

export const ssoLogin = (params) => { return axios.post(`${iffApi}/sso/login`, params).then(rsp => rsp); };

export const loginZbry = (params) => { return axios.post(`${iffApi}/security/login`, params).then(rsp => rsp); };

// 获取环境变量
export const queryEnvDetail = () => { return axios.get(`${iffApi}/env/queryEnvDetail?ssdw=hongmeng`); };

export const getEnvInfo = (ssdw, envId) => { return axios.get(`${iffApi}/env/getEnvInfo/${ssdw}/${envId}`); };

// 获取二维码
export const createTmpQrcode = (type) => { return axios.get(`${iffApi}/tzgl/createTmpQrcode/${type}`); };
// 获取用户openID
export const getQrcodeOpenid = (sceneStr) => { return axios.get(`${iffApi}/tzgl/getQrcodeOpenid/${sceneStr}`); };
// 微信绑定用户
export const wxBindUser = (params) => { return axios.post(`${iffApi}/tzgl/bind/wxuser`, params).then(rsp => rsp); };
// 微信登录
export const wxscanLogin = (openid) => { return axios.post(`${iffApi}/security/wxlogin/${openid}`).then(rsp => rsp); };
// 人脸识别登录
export const rlsbLogin = (fd) => { return axios.post(`${authCtx}/auth/security/loginsys`, fd).then(rsp => rsp); };

export const ZbryQd = (zbrybm) => { return axios.post(`${iffApi}/security/zbryQd/${zbrybm}`).then(rsp => rsp); };

export const hjxxjk = () => { return axios.post(`${iffApi}/hjxxjk/hj`); };

export const gzxxjk = () => { return axios.post(`${iffApi}/hjxxjk/gz`); };

// 介绍指令
export const instruct = (params) => { return axios.get(`${iffApi}/txgl/zlFs`, { params: params }); };
// 解锁指令
export const unlockInstruct = (params) => { return axios.get(`${iffApi}/txgl/unlockAll`, { params: params }); };
export const cancelInstruct = (params) => { return axios.get(`${iffApi}/txgl/cancel`, { params: params }); };

// 当前用户菜单列表
export const getUserMenus = () => { return axios.get(`${commontCtx}/menu/currentUser/menu/tree`); };

// 退出
export const logoutSys = () => { return axios.post(`${authCtx}/auth/security/logout`); };
export const logoutZbry = (zbrybm) => { return axios.post(`${iffApi}/security/zbryQt/${zbrybm}`); };

// 获取身份信息
export const getSubject = () => { return axios.get(`${authCtx}/auth/security/info`); };

// 获取系统枚举
export const getEnum = () => { return axios.get(`${commontCtx}/enums`); };

// 切换身份
export const changeSubject = (capacityCode) => { return axios.post(`${authCtx}/auth/security/switch?capacityCode=${capacityCode}`); };

// 获取用户授权的的页面元素
export const getRoleElement = () => { return axios.get(`${commontCtx}/role/element`); };

// 密码强度校验
export const passwordCheck = (password) => { return axios.post(`${commontCtx}/admin/password/check`, password); };

// 密码修改
export const changepwd = (fd) => { return axios.put(`${commontCtx}/person/changepwd`, fd); };

// 机构部门（无权限）
export const getDeptTree = (params) => { return axios.get(`${commontCtx}/dept/getDepts`, { params: params }); };

/* =======================================
   业务枚举服务start
*/
export const getIffEnum = () => { return axios.get(`${iffApi}/enums`); };
/* 业务枚举服务end */

export const systemPreloadInfo = () => {
  // 调用 getExamplesApiEnum 业务枚举
  return axios.all([getSubject()]);
};

export const faceService = {
  // 检测人脸是否已开启
  checkFaceStatus: () => { return axios.post(`${commontCtx}/face/sfkq`); },

  closeFaceRecogize: () => { return axios.delete(`${commontCtx}/face`); }
};
