/*
 * @Author: DQL
 * @Date: 2020-03-05 17:31:01
 * @LastEditors: zxf
 * @LastEditTime: 2023-08-07 18:06:50
 * @Description: file content
 */
const config = {
  // 系统信息
  app: {
    code: 'kestrel-iff-screen',
    name: '消防数字哨兵',
    welcome: 'Hi,您好',
    welcome_cn: '欢迎使用消防数字哨兵',
    welcome_en: 'Welcome To Use Remote-monitoring System Of Urban Fire Protection'
  },
  // 授权信息
  copyright: 'Copyright © 2022 广东联通通信建设有限公司',

  /* =============================================
     右侧导航菜单配置start
    *
  */

  isCollapseMenu: false, // 导航菜单是否默认收起状态

  hasMenuSearch: true, // 导航菜单搜索功能是否开启

  /* 右侧导航菜单配置end */

  /* =============================================
     导航页签navTab配置start
    *
   */
  isFirstNavtab: false, // 配置导航页签navTab是否设定首页

  // 首页页签配置
  firstNavtab: {
    title: '首页', // navTab显示标题
    isCache: true, // 页面缓存
    hasClose: false, // 是否可关闭（默认首页不可关闭）
    routeName: 'home',
    routePath: '/home'
  },

  openNavtabLimit: 20, // 打开的页签数量限制
  /* 导航页签navTab配置 End */

  /* =============================================
     请求服务配置 Start
    *
   */

  // 服务端认证token名称
  serverHeaderTokenName: 'iffrobot-auth-token',

  // 浏览器本地token名称
  localTokenName: 'iffrobot-auth-token',

  // 公共ctx
  commontCtx: process.env.VUE_APP_COMMON_CTX,
  authCtx: process.env.VUE_APP_CTX_AUTHAPI,

  // 服务ctx
  ctx: {
    hmfileApi: process.env.VUE_APP_CTX_HMFILEAPI, // 文件服务
    workflowCtx: process.env.VUE_APP_WORKFLOW_CTX, // 工作流ctx
    logApi: process.env.VUE_APP_CTX_LOGAPI, // log服务
    iffApi: process.env.VUE_APP_CTX_IFFAAPI, // 智慧消防服务
    rtspApi: process.env.VUE_APP_CTX_RTSPAPI,
    liveApi: process.env.VUE_APP_CTX_LIVEAPI // 直播服务
  },

  fileApi: {
    // 文件服务预加载api
    initUpload: process.env.VUE_APP_CTX_HMFILEAPI + '/initupload',

    // 文件服务上传api
    upload: process.env.VUE_APP_CTX_HMFILEAPI + '/upload',

    // 文件服务预加载api
    initDownload: process.env.VUE_APP_CTX_HMFILEAPI + '/initdownload',

    // 文件服务下载api
    download: process.env.VUE_APP_CTX_HMFILEAPI + '/download',

    // 文件服务下载api
    query: process.env.VUE_APP_CTX_HMFILEAPI + '/query'

  },
  /* 请求服务配置 End */

  omenMusicSrc: {
    warning: process.env.BASE_URL + 'assets/mp3/2206.mp3'
  }

};

export default config;
