/*
 * @Author: DQL
 * @Date: 2020-03-05 17:14:24
 * @LastEditors: DQL
 * @LastEditTime: 2020-04-28 18:12:37
 * @Description: file content
 */
import Vue from 'vue';
import Vuex from 'vuex';

/* =============================================
   业务数据管理模块引入start
  *
  *
*/

/* 业务数据管理模块引入end */

/* =============================================
   业务数据管理模块写入start
  *
  *
*/

const FEATURES_STORE = {};

/* 业务数据管理模块写入end */

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    apiEnums: {}
  },
  mutations: {
    UPDATE_ENUMS: (state, _apiEnums) => {
      _apiEnums.forEach(_api => {
        const cates = {};
        _api.data.map(item => {
          /* eslint-disable */
          eval('cates.' + item.cateNameEn + ' = item.enums')
          /* eslint-enable */
          // cates[item.cateNameEn] = item.enums
        });
        state.apiEnums[_api.name] = cates;
      });

      console.info('执行：业务枚举apiEnums更新', state.apiEnums);
    }
  },
  actions: {
  },
  modules: {
    // 业务数据管理
    ...FEATURES_STORE
  },
  strict: process.env.NODE_ENV !== 'production'
});
