<template>
  <div class="dev-o-echart">
    <div
      class="dev-o-gwgrid"
      style="width: 100%;height: 100%;"
    >
      <div
        v-for="item in chartData"
        :key="item.id"
        class="dev-o-gwgrid__item"
        :class="{
          'dev-o-gwgrid__item--normal': item.bjzt === '01'||item.bjzt === '99',
          // 'dev-o-gwgrid__item--warning': item.sz >= 0.2 && item.sz < 0.4,
          'dev-o-gwgrid__item--danger': item.bjzt === '20'|| item.bjzt === '10',
        }"
      >
        <div class="dev-o-gwgrid__title">
          {{ item.azwz && item.azwz.length > 11?item.azwz.substring(0,10)+'...':item.azwz }}
        </div>
        <el-tooltip

          effect="dark"
          :content="getTsStr(item)"
          placement="bottom"
        >
          <div class="dev-o-gwgrid__body">
            <span class="dev-o-gwgrid__body_sz">
              {{ item.sz }}<br>
            </span>
            <!-- <span class="dev-o-gwgrid__body_azwz">
              {{ item.azwz }}
            </span> -->
          </div>
        </el-tooltip>
      </div>
    </div>
    <div
      v-if="chartData.length===0"
      class="dev-o-echart__empty"
    >
      暂无记录！
    </div>
  </div>
</template>

<script>
import { zhxfService } from './zhxf.service';

export default {
  name: 'GwylChart',
  props: {
    code: {
      type: String,
      default: null
    },
    ssbm: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      chartData: [],
      chartDataF: {}
    };
  },
  methods: {
    // getBjh (item) {
    //   const bjqh = item.bjqh ? item.bjqh : '';
    //   const bjhlh = item.bjhlh ? item.bjhlh : '';
    //   const bjwh = item.bjwh ? item.bjwh : '';
    //   const bjh = bjqh + '-' + bjhlh + '-' + bjwh;
    //   return bjh;
    // },
    getTsStr (item) {
      const azwz = item.azwz ? item.azwz : '待录入';
      const bjqh = item.bjqh ? item.bjqh : '';
      const bjhlh = item.bjhlh ? item.bjhlh : '';
      const bjwh = item.bjwh ? item.bjwh : '';
      return '安装位置：' + azwz + '  ' + '部件号：' + bjqh + '-' + bjhlh + '-' + bjwh + '   ' + '压力值：' + item.sz;
    },
    getServerData () {
      // 模拟从服务器获取数据时的延时
      setTimeout(() => {
        // 模拟服务器返回数据，如果数据格式和标准格式不同，需自行按下面的格式拼接
        const res = {
          categories: ['2016', '2017', '2018', '2019', '2020', '2021'],
          series: [
            {
              name: '成交量A',
              lineType: 'dash',
              data: [35, 8, 25, 37, 4, 20]
            },
            {
              name: '成交量B',
              data: [70, 40, 65, 100, 44, 68]
            },
            {
              name: '成交量C',
              data: [100, 80, 95, 150, 112, 132]
            }
          ]
        };
        this.chartDataF = JSON.parse(JSON.stringify(res));
      }, 500);
    },
    /**
     * 获取后端报表数据
     */
    getChartData () {
      const _this = this;
      zhxfService.queryGwyl(this.code, this.ssbm).then(resp => {
        _this.chartData = resp.data.values;
        _this.getServerData();
      });
    },
    clearClock () {
      clearInterval(this.clock);
      this.clock = null;
    },
    addClock () {
      if (this.code && !this.clock) {
        this.getChartData();
        this.clock = setInterval(this.getChartData, 30 * 1000);
      }
    }
  },
  watch: {
    code (newVal, oldVal) {
      this.clearClock();
      if (newVal) {
        this.getChartData();
        this.addClock();
      }
    }
  },
  mounted () {
    this.addClock();
  },
  beforeDestroy () {
    this.clearClock();
  }
};
</script>

<style scoped lang="scss">

.dev-o-gwgrid::-webkit-scrollbar {
  width: 4px;
  background: #09369A;
}
.dev-o-gwgrid::-webkit-scrollbar-thumb {
  background: #09369A;
}

.dev-o-gwgrid {
  margin-top: 20px;
  display: grid;
  grid-template-columns: auto auto auto;
  overflow-y: auto;
  overflow-x: hidden;
  gap: 6px;
  width: 114px;
  height: 74px;
  background: #0026624d;
  border-radius: 2px;
  text-align: center;

  &__title{
    width: 114px;
    height: 25px;
    background-image: linear-gradient(270deg, #3d8fff00 0%, #0068f445 100%);
    border-radius: 2px;
    line-height: 25px;
  }
  &__body{
    margin-top: 5px;
    line-height: 35px;
    &_sz{
      font-size: 16px;
      color: #3DFBFF;
    }
    &_azwz{
      font-family: AlibabaPuHuiTi_2_55_Regular;
      font-weight: 400;
      font-size: 12px;
      color: #FFFFFF;
    }

  }
}

.dev-o-gwgrid__item {
  padding: 20px 3;
  font-size: 12px;

  width: 114px;
  color: #2FCDFF;
  height: 74px;
  background: #0026624d;

  // &--normal {
  //   // background-color: #147fd3;
  // }

  &--warning {
    background-color: #ffaf81;
  }

  &--danger {
    background-color: #fb557d;
  }
}

.dev-o-gwgrid__item-val {
  font-weight: 600;
}
</style>
