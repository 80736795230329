/*
 * @Author: liubin
 * @Date: 2021-03-29 09:49:10
 * @LastEditors: liubin
 * @LastEditTime: 2021-03-29 09:54:30
 * @Description: content
 */
const yysbRouters = {
  path: 'yysb',
  name: 'yysb',
  component: { template: '<router-view></router-view>' },
  children: [
    {
      path: 'wzzyy',
      name: 'wzzyy',
      component: () => import(/* webpackChunkName: "cyjdxytz" */ '@/features/yysb/wzzyy.vue'),
      meta: {
        navtabTitle: '文字转语音',
        isCache: true,
        ignoreDiff: [],
        isSecondary: false
      }
    },
    {
      path: 'yyzwz',
      name: 'yyzwz',
      component: () => import(/* webpackChunkName: "cyjdxytz" */ '@/features/yysb/yyzwz.vue'),
      meta: {
        navtabTitle: '语音转文字',
        isCache: true,
        ignoreDiff: [],
        isSecondary: false
      }
    }
  ]
};

export default yysbRouters;
