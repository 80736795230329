/*
 * @Author: cgh
 * @Date: 2022-10-18 15:52:03
 * @LastEditors: zxf
 * @LastEditTime: 2023-12-26 17:34:21
 * @Description: file content
 */

import axios from 'axios';
import config from '@/config';

const { iffApi } = config.ctx;

export const virtualService = {

  // 监控室
  queryJqrjk: () => { return axios.get(`${iffApi}/dp/jqrJks`); },

  // 消防设备
  queryXfsb: (lwdw) => { return axios.get(`${iffApi}/dp/xfsb/${lwdw}`); },

  // 未报修的故障消防设备
  queryXfsbGz: () => { return axios.get(`${iffApi}/dp/xfsb/gz`); },

  // 消防维保情况
  queryXfwbqk: (lwdw) => { return axios.get(`${iffApi}/dp/xfwbqk/${lwdw}`); },

  // 建筑物概述
  queryJzw: (lwdw) => { return axios.get(`${iffApi}/dp/jzw/${lwdw}`); },

  // 值班人员
  queryZbry: (lwdw) => { return axios.get(`${iffApi}/dp/jzzb/${lwdw}`); },

  // 火警信息
  queryHjxx: (lwdw) => { return axios.get(`${iffApi}/dp/hjxx/${lwdw}`); },

  // 设备故障信息
  querySbxx: (lwdw) => { return axios.get(`${iffApi}/dqhz/sbxx/${lwdw}`); },
  // 电气火灾信息
  queryDqxx: (lwdw) => { return axios.get(`${iffApi}/dqhz/dqxx/${lwdw}`); },
// 警报统计
  queryJbTj: (lwdw) => { return axios.get(`${iffApi}/dqhz/jbtj/${lwdw}`); },

  getHjxxByToday: (lwdw) => { return axios.get(`${iffApi}/dp/indx/hjxxTjByToday/${lwdw}`); },

  // 本周告警信息
  getHjxxByWeek: (lwdw) => { return axios.get(`${iffApi}/dp/indx/hjxxTjByWeek/${lwdw}`); },

  // 获取是否存在报警情况信息
  getHjxx: (lwdw) => { return axios.get(`${iffApi}/dp/hjxx/now/${lwdw}`); }

};
