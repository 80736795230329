import TTSRecorder from '../../yysb/js/audio';
import IatRecorder from '../../yysb/js/index';

import axios from 'axios';
import config from '@/config';

const ttsRecorder = new TTSRecorder();
const iatRecorder = new IatRecorder({
  language: 'zh_cn',
  accent: 'mandarin',
  appId: '2e6469a4',
  onTextChange: function (text) {
    // 监听识别结果的变化
    // 3秒钟内没有说话，就自动关闭
    // console.log(text);
    // if (text) {
    //   clearTimeout(times);
    //   times = setTimeout(() => {
    //     this.stop(); // voice.stop();
    //     fixedBox.style.display = 'none';
    //   }, 3000);
    // }
    ;
  }
});
let times, nplText;

export const nplService = {
  /**
   * 根据文本转换成语音播放
   * @param {*} voiceTxt 文本字符串
   */
  play: (voiceTxt, endFunc) => {
  // 要合成的文本
    ttsRecorder.setParams({
    // 文本内容
      text: voiceTxt,
      // 角色
      //  voiceName: '',
      // 语速
      speed: 70,
      // 音量
      voice: 50
    });
    ttsRecorder.start();
    // 判断语音播放是否结束
    const clock = setInterval(() => {
      // console.log('语音播放结束:', ttsRecorder.status === 'endPlay');
      if (ttsRecorder.status === 'endPlay') {
        clearTimeout(clock);
        endFunc instanceof Function && endFunc();
      }
    }, 100);
  },
  /**
   * 暂停语音播放
   */
  stopPlay: () => {
    ttsRecorder.stop();
  },
  listen: (func, endFunc) => {
    iatRecorder.onTextChange = (text) => {
      if (func instanceof Function) {
        nplText = text;
        func(nplText);
        console.log(`语音识别结果:${nplText}`);
        // 超过3秒没说话
        if (text) {
          clearTimeout(times);
          times = setTimeout(() => {
            console.log('语音识别结果 end');
            iatRecorder.stop();
          }, 2000);
        }
      }
    };
    iatRecorder.start();
    // 判断语音识别是否结束
    const times2 = setInterval(() => {
      console.log('语音识别websocket结束:', iatRecorder.status === 'end', iatRecorder.resultTextTemp);
      if (iatRecorder.status === 'end') {
        clearTimeout(times2);
        iatRecorder.stop();
        endFunc(iatRecorder.resultTextTemp);
        // 关闭人机对话前关闭麦克风
        if (!iatRecorder.resultTextTemp) {
          iatRecorder.recorderOut();
        }
      }
    }, 1000);
  },
  stopListen: () => {
    iatRecorder.stop();
  }
};

const { iffApi } = config.ctx;
export const iffService = {
  // 语音文本解析
  recognizeText: (params) => { return axios.get(`${iffApi}/recognize/text`, { params: params }); },
  // 值班台账查询
  queryZbjl: (params) => { return axios.get(`${iffApi}/zbjl`, { params: params }); },
  // 值班查岗台账查询
  queryZbcg: (params) => { return axios.get(`${iffApi}/zbcg`, { params: params }); },
  // 统计最近6个月的巡查信息
  queryXcxxByMonths: () => { return axios.get(`${iffApi}/dp/xcxx/listByMonths`); },
  // 统计最近6个月的检测信息
  queryJcxxByMonths: () => { return axios.get(`${iffApi}/dp/jcxx/listByMonths`); }
};
