<!--
 * @Author: DQL
 * @Date: 2020-03-30 19:02:49
 * @LastEditors: cgh
 * @LastEditTime: 2023-03-24 21:47:52
 * @Description: file content
 -->
<template>
  <router-view />
</template>

<script>
import config from '@/config.js';
import packageJson from '../package.json';
export default {
  name: 'Layout',
  data () {
    return {
      appLogo: process.env.BASE_URL + 'assets/logo.png',
      config: config,
      packageJson: packageJson,
      env: process.env.NODE_ENV

    };
  },
  methods: {
  },
  mounted () {
    document.getElementById('preloading').style.display = 'none';
  }
};
</script>
