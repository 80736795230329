/* globals AFRAME, THREE */
const d3 = Object.assign({}, require('d3-selection'), require('d3-scale'), require('d3-geo'), require('d3-geo-projection'));

const extrudeMeshKey = 'extrudeMap';
const outlineMeshKey = 'outlineMap';
const makerMeshKey = 'markerMesh';
const GEO_SRC_LOADED_EVENT = 'geo-src-loaded';
const GEO_DATA_READY_EVENT = 'geo-data-ready';

if (typeof AFRAME === 'undefined') {
  throw new Error('Component attempted to register before AFRAME was available.');
}

const projectionUtils = {
  /**
   * @param projectionName the name of a projection from d3-geo or d3-geo-projection
   * @returns the d3 projection or transform specified by the projectionName
   * @throws Error if projectionName doesn't exist in d3-geo or d3-geo-projection
   */
  getD3Projection: function (projectionName) {
    const projection = d3[projectionName];
    if (typeof projection !== 'function') {
      throw Error('Invalid d3 projection; use a projection from d3-geo or d3-geo-projection');
    }
    return projection();
  },

  /**
   * @param height the height in A-Frame units
   * @param width the width in A-Frame units
   * @returns a d3 transform that converts from normal SVG screen coordinates
   *          (an origin of [0,0] with y pointing down) to A-Frame coordinates
   *          where the extent is based on the height and width, the origin is
   *          in the center, and y points up
   */
  getWorldTransform: function (height, width) {
    const x = d3.scaleLinear().domain([0, width]).range([-width / 2, width / 2]);
    const y = d3.scaleLinear().domain([0, height]).range([height / 2, -height / 2]);

    return d3.geoTransform({
      point: function (px, py) {
        this.stream.point(x(px), y(py));
      }
    });
  },

  /**
   * @param projectionName the name of a projection from d3-geo or d3-geo-projection
   * @param height the height in A-Frame units
   * @param width the width in A-Frame units
   * @returns a d3 projection stream which centers the given geoJson in
   *          A-Frame coordinates and scales it to fit the height and width
   *          of the component
   */
  getFittedProjection: function (projectionName, geoJson, height, width) {
    const projection = this.getD3Projection(projectionName).fitSize([width, height], geoJson);
    const worldTransform = this.getWorldTransform(height, width);
    // Thanks to this StackOverflow answer on how to chain streams:
    // https://stackoverflow.com/a/31647135
    return {
      stream: function (s) {
        return projection.stream(worldTransform.stream(s));
      }
    };
  }

};
class ThreeJSRenderContext {
  /**
   * A rendering context that can be used with d3-geo to convert geoJSON
   * into data that can be used by THREE.js.  To do this, it implements
   * the d3-geo required subset of the CanvasRenderingContext2D API.
   *
   * @param shapePath a THREE.ShapePath that will hold the rendered data
   * @constructor
   * @see https://github.com/d3/d3-geo#path_context
   */
  constructor (shapePath) {
    this.shapePath = shapePath;
  }

  beginPath = function beginPath () {
    // no-op
  };

  moveTo = function moveTo (x, y) {
    this.shapePath.moveTo(x, y);
  };

  lineTo = function lineTo (x, y) {
    this.shapePath.lineTo(x, y);
  };

  arc = function arc (x, y, radius, startAngle, endAngle) {
    this.shapePath.currentPath.arc(x, y, radius, startAngle, endAngle);
  };

  closePath = function closePath () {
    this.shapePath.currentPath.closePath();
  };

  /**
   * Exports the data stored in this context into an array of Shapes.
   * By default solid shapes are defined clockwise (CW) and holes are
   * defined counterclockwise (CCW). If isCCW is set to true, then those
   * are flipped. If the parameter noHoles is set to true then all paths
   * are set as solid shapes and isCCW is ignored.
   *
   * The isCCW flag is important when rendering topoJSON vs. geoJSON.  For
   * features smaller than a hemisphere, topoJSON uses clockwise shapes while
   * geoJSON uses counterclockwise shapes.  For features larger than a
   * hemisphere (such as oceans), the opposite is true.
   *
   * @param isCCW changes how solids and holes are generated
   * @param noHoles whether or not to generate holes
   * @return {Array} of THREE.Shape objects
   * @see https://github.com/d3/d3-geo for a summary of winding order convention
   */
  toShapes = function toShapes (isCCW, noHoles) {
    return this.shapePath.toShapes(isCCW, noHoles);
  };

  /**
   * Exports the data stored in this context into an array of vertices.  Each
   * vertex takes up three positions in the array so it is optimized to populate
   * a THREE.BufferGeometry.  The z parameter can be used to control the position of
   * the vertices on the z-axis.
   *
   * @param z optional parameter to set as the z-value for all the vertices produced; 0 will be used if no z is specified
   * @return {Array} of numbers
   */
  toVertices = function toVertices (z) {
    const verticesForShape = [];
    const zVal = z || 0;
    this.shapePath.subPaths.forEach(function (path) {
      path.curves.forEach(function (curve) {
        if (curve.isLineCurve) {
          verticesForShape.push(curve.v1.x);
          verticesForShape.push(curve.v1.y);
          verticesForShape.push(zVal);
          verticesForShape.push(curve.v2.x);
          verticesForShape.push(curve.v2.y);
          verticesForShape.push(zVal);
        } else {
          curve.getPoints().forEach(function (point) {
            verticesForShape.push(point.x);
            verticesForShape.push(point.y);
            verticesForShape.push(zVal);
          });
        }
      });
    });
    return verticesForShape;
  };
}

const generateUtils = {
  renderToContext (geoJson, projection, pointRadius = 0.1) {
    const shapes = new THREE.ShapePath();
    const mapRenderContext = new ThreeJSRenderContext(shapes);
    const mapPath = d3.geoPath(projection, mapRenderContext).pointRadius(pointRadius);
    mapPath(geoJson);
    return mapRenderContext;
  },

  generateShape (geoJson, projection, isCCW, pointRadius) {
    const mapRenderContext = this.renderToContext(geoJson, projection, pointRadius);

    // const points = [
    //   { x: 0, y: 0 },
    //   { x: 0, y: 10 },
    //   { x: 10, y: 10 },
    //   { x: 10, y: 0 }
    // ];=
    // shapes.moveTo(points[0].x, points[0].y);
    // for (let index = 1; index < points.length; index++) {
    //   const p = points[index];
    //   const nextP = points[index + 1];
    //   shapes.lineTo(p.x, p.y);
    //   console.log(index, p, nextP);
    // };
    // shapes.lineTo(points[0].x, points[0].y);

    return mapRenderContext.toShapes(isCCW);
  },

  latLngToVec3: function (lat, lon) {
    const geomComponent = this.el.components.geometry;
    if (geomComponent.data.primitive === 'sphere') {
      return this._sphericalLatLngToVec3(lat, lon, geomComponent.data.radius);
    } else if (geomComponent.data.primitive === 'plane') {
      return this._planarLatLngToVec3(lat, lon, geomComponent.data.width, geomComponent.data.height);
    }
  },
  _planarLatLngToVec3: function (lng, lat, width, height) {
    return new THREE.Vector3(
      lng / 360 * width - (width / 2),
      -lat / 180 * height + (height / 2),
      0);
  },
  /**
   * 根据经纬度，转换成3维坐标
   * @param {*} lng 经度
   * @param {*} lat 维度
   * @param {*} radius 地球半径
   * @returns THREE.Vector3的3维坐标
   */
  _sphericalLatLngToVec3: function (lng, lat, radius) {
    const phi = (180 + lng) * (Math.PI / 180);
    const theta = (90 - lat) * (Math.PI / 180);
    const x = -radius * Math.sin(theta) * Math.cos(phi);
    const y = radius * Math.cos(theta);
    const z = radius * Math.sin(theta) * Math.sin(phi);

    return new THREE.Vector3(x, y, z);
  }
};

/**
 * Handles loading GeoJSON data from a file.
 */
AFRAME.registerComponent('geojson-loader', {
  schema: {
    src: {
      type: 'asset'
    }
  },

  init: function () {
    this.loader = new THREE.FileLoader();
    this.onSrcLoaded = this.onSrcLoaded.bind(this);
  },

  update: function (oldData) {
    if (!this.data.src) {
      return;
    }
    if (this.data.src !== oldData.src) {
      this.loader.load(this.data.src, this.onSrcLoaded);
    }
  },

  onSrcLoaded: function (text) {
    const geoJson = JSON.parse(text);
    this.el.emit(GEO_SRC_LOADED_EVENT, { geoJson: geoJson });
  }
});

/**
 * Projects GeoJSON to VR space.
 */
AFRAME.registerComponent('geo-projection', {
  schema: {
    projection: {
      default: 'geoStereographic'
    },
    width: { default: 1 },
    height: { default: 1 }
  },

  init: function () {
    this.onSrcLoaded = this.onSrcLoaded.bind(this);
    this.el.addEventListener(GEO_SRC_LOADED_EVENT, this.onSrcLoaded);
  },

  remove: function () {
    this.el.removeEventListener(GEO_SRC_LOADED_EVENT, this.onSrcLoaded);
  },

  update: function (oldData) {
    if (!this.geoJson) {
      return;
    }
    const differences = AFRAME.utils.diff(oldData, this.data);
    if (Object.keys(differences).length > 0) {
      this.projectGeoJson();
    }
  },

  onSrcLoaded: function (event) {
    this.geoJson = event.detail.geoJson;
    this.projectGeoJson();
  },

  projectGeoJson: function () {
    this.projection = projectionUtils.getFittedProjection(this.data.projection, this.geoJson, this.data.height, this.data.width);
    this.el.emit(GEO_DATA_READY_EVENT, {});
  }
});

// 生成地图，挤出固定高度
AFRAME.registerComponent('geo-extrude-renderer', {
  dependencies: ['geo-projection', 'material'],
  schema: {
    parentGeoProjection: { default: '' },
    splitMesh: { default: false },
    curveSegments: { default: 12 },
    steps: { default: 1 },
    depth: { default: 1 },
    depthColor: { type: 'color', default: '#2b3c5e' },
    bevelEnabled: { default: true },
    bevelThickness: { default: 0.2 },
    bevelSize: { default: 0.1 },
    bevelOffset: { default: 0 },
    bevelSegments: { default: 3 },
    isCCW: {
      type: 'boolean',
      default: false
    },
    sphereRadius: {
      default: 0
    }
  },
  init: function () {
    document.addEventListener(GEO_DATA_READY_EVENT, e => {
      if (e.target === this.el) {
        this.render();
      }
    });
  },
  update: function (oldData) {
    const data = this.data;

    const diff = AFRAME.utils.diff(oldData, data);
    if (diff) {
      if (!this.el.getObject3D(extrudeMeshKey)) return;
      this.render();
    }
  },
  remove: function () {
    this.el.removeEventListener(GEO_DATA_READY_EVENT, this.render);
    const obj = this.el.getObject3D(extrudeMeshKey);
    if (obj) {
      this.el.removeObject3D(extrudeMeshKey);
    }
  },
  tick: function (time, delta) {},

  render: function () {
    this.el.getObject3D(extrudeMeshKey) && this.el.removeObject3D(extrudeMeshKey);

    const data = this.data;
    const parentGeoProjection = data.parentGeoProjection;
    const geoProjectionComponent = this.el.components['geo-projection'];

    const geoJson = geoProjectionComponent.geoJson;
    let projection = geoProjectionComponent.projection;
    if (parentGeoProjection !== '') {
      projection = document.querySelector(parentGeoProjection).components['geo-projection'].projection;
    }
    const material = this.el.components.material.material; // 表面材质
    const depthMaterial = new THREE.MeshStandardMaterial({ color: data.depthColor }); // 挤压出的侧面材质
    const extrudeSetting = {
      depth: data.depth,
      bevelThickness: data.bevelThickness,
      bevelSize: data.bevelSize,
      bevelOffset: data.bevelOffset,
      bevelSegments: data.bevelSegments,
      bevelEnabled: data.bevelEnabled,
      curveSegments: data.curveSegments,
      steps: data.steps
    };

    const group = new THREE.Group();
    if (data.splitMesh) {
      // 拆分成多个网格物体
      geoJson.features.forEach(feature => {
        const aGeoJson = {
          type: 'FeatureCollection',
          features: [feature]
        };
        const shapes = generateUtils.generateShape(aGeoJson, projection, data.isCCW);
        const geometry = new THREE.ExtrudeGeometry(shapes, extrudeSetting);
        const mesh = new THREE.Mesh(geometry, [material, depthMaterial]);
        mesh.geoData = feature.properties;
        group.add(mesh);
      });
    } else {
      const shapes = generateUtils.generateShape(geoJson, projection, data.isCCW);
      const geometry = new THREE.ExtrudeGeometry(shapes, extrudeSetting);
      group.add(new THREE.Mesh(geometry, [material, depthMaterial]));
    }
    this.el.setObject3D(extrudeMeshKey, group);
  },

  // 根据地球半径，设置平面地图所处的位置和角度
  fitSphere () {
    const geoProjectionComponent = this.el.components['geo-projection'];
    const radius = this.data.sphereRadius;
    if (radius !== 0) {
      const geoJson = geoProjectionComponent.geoJson;
      const mesh = this.el.getObject3D(extrudeMeshKey);

      // 经纬度=>3维坐标点
      const center = geoJson.features[0].properties.centroid;
      const lat = center[0]; // 经度
      const lng = center[1]; // 维度
      const positionPoint = generateUtils._sphericalLatLngToVec3(lat, lng, radius);
      console.log('fitSphere', lat, lng, positionPoint);

      // 设置平面地图的position位置坐标
      // mesh.position.copy(positionPoint);
      mesh.position.set(0, 0, 1);
      // const dw = Math.PI / 180;
      // mesh.rotation.set(0, 0, -45 * dw);

      // 旋转
      // const euler = new THREE.Euler(lng / 90 * (Math.PI / 2), 0, 0);
      // mesh.quaternion.setFromEuler(euler);

      // // mesh在球面上的法线方向(球心和球面坐标构成的方向向量)
      // const coordVec3 = positionPoint.normalize();
      // // mesh默认在XOY平面上，法线方向沿着z轴new THREE.Vector3(0, 0, 1)
      // const meshNormal = new THREE.Vector3(0, 0, 1);
      // // 四元数属性.quaternion表示mesh的角度状态
      // // .setFromUnitVectors();计算两个向量之间构成的四元数值
      // mesh.quaternion.setFromUnitVectors(meshNormal, coordVec3);
      // console.log('fitSphere', positionPoint, meshNormal, coordVec3);
    }
  }
});

// 地图描边
AFRAME.registerComponent('geo-outline-renderer', {
  dependencies: ['geo-projection', 'material'],

  schema: {
    parentGeoProjection: { default: '' },
    color: {
      type: 'color',
      default: ''
    },
    position: {
      type: 'vec3',
      default: new THREE.Vector3(0, 0, 0)
    }
  },

  init: function () {
    this.el.addEventListener(GEO_DATA_READY_EVENT, e => {
      if (e.target === this.el) {
        this.render();
      }
    });
  },

  update: function (oldData) {
    const data = this.data;

    const diff = AFRAME.utils.diff(oldData, data);
    if (diff) {
      if (!this.el.getObject3D(outlineMeshKey)) return;
      this.render();
    }
  },

  remove: function () {
    this.el.removeEventListener(GEO_DATA_READY_EVENT, this.render);
    const obj = this.el.getObject3D(outlineMeshKey);
    if (obj) {
      this.el.removeObject3D(outlineMeshKey);
    }
  },

  render: function () {
    this.el.getObject3D(outlineMeshKey) && this.el.removeObject3D(outlineMeshKey);
    const data = this.data;
    const parentGeoProjection = data.parentGeoProjection;
    const geoProjectionComponent = this.el.components['geo-projection'];

    let material = this.el.components.material.material;
    if (data.color) {
      material = new THREE.LineBasicMaterial({ color: data.color });
    }

    const geoJson = geoProjectionComponent.geoJson;
    let projection = geoProjectionComponent.projection;
    if (parentGeoProjection !== '') {
      projection = document.querySelector(parentGeoProjection).components['geo-projection'].projection;
    }

    const mapRenderContext = generateUtils.renderToContext(geoJson, projection);
    const lineGeometry = new THREE.BufferGeometry();
    const vertices = mapRenderContext.toVertices();
    lineGeometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
    const outlineMesh = new THREE.LineSegments(lineGeometry, material);
    const point = data.position;
    outlineMesh.position.set(point.x, point.y, point.z);
    this.el.setObject3D(outlineMeshKey, outlineMesh);
  }
});

// 地图名称显示
const geoTextMeshKey = 'geoTextMesh';
AFRAME.registerComponent('geo-text-renderer', {
  dependencies: ['geo-projection', 'material'],

  schema: {
    parentGeoProjection: { default: '' },
    font: { default: '#font-STSONG' },
    fontSize: { default: 0.2 },
    color: {
      type: 'color',
      default: '#FFF'
    },
    opacity: { default: 1 },
    positionZ: { default: 0 }
  },

  init: function () {
    this.el.addEventListener(GEO_DATA_READY_EVENT, e => {
      if (e.target === this.el) {
        this.render();
      }
    });
  },

  update: function (oldData) {
    const data = this.data;

    const diff = AFRAME.utils.diff(oldData, data);
    if (diff) {
      if (!this.checkRender()) return;
      this.render();
    }
  },

  remove: function () {
    this.checkRender() && this.clearRender();
  },

  checkRender: function () {
    return this.el.querySelectorAll(`.${geoTextMeshKey}`).length > 0;
  },

  clearRender: function () {
    this.el.querySelectorAll(`.${geoTextMeshKey}`).forEach(aEl => {
      this.el.removeChild(aEl);
    });
  },

  render: function () {
    this.checkRender() && this.clearRender();

    const data = this.data;
    const parentGeoProjection = data.parentGeoProjection;
    const geoProjectionComponent = this.el.components['geo-projection'];

    const geoJson = geoProjectionComponent.geoJson;
    let projection = geoProjectionComponent.projection;
    if (parentGeoProjection !== '') {
      projection = document.querySelector(parentGeoProjection).components['geo-projection'].projection;
    }

    const path = d3.geoPath(projection);

    for (let index = 0; index < geoJson.features.length; index++) {
      const aGeoFeatures = geoJson.features[index];
      const centroid = path.centroid(aGeoFeatures);
      const name = aGeoFeatures.properties.name;

      const aEl = document.createElement('a-troika-text');
      aEl.className = geoTextMeshKey;
      aEl.setAttribute('material', `shader: standard; emissive: ${data.color}; opacity: ${data.opacity}`);
      aEl.setAttribute('font', data.font);
      aEl.setAttribute('font-size', data.fontSize + '');
      aEl.setAttribute('value', name);
      aEl.setAttribute('position', `${centroid[0]} ${centroid[1]} ${data.positionZ}`);
      this.el.appendChild(aEl);
    }
  }
});

// 鼠标选中物体事件
AFRAME.registerComponent('geo-cursor', {
  dependencies: ['geo-projection', 'geo-extrude-renderer'],

  schema: {
    enabled: { default: true },
    color: {
      type: 'color',
      default: ''
    }
  },

  init: function () {
    this.el.addEventListener(GEO_DATA_READY_EVENT, this.bindEvent.bind(this));
  },

  update: function (oldData) {
    const diff = AFRAME.utils.diff(oldData, this.data);
    if (!diff.enabled) {
      this.resetMaterial();
    }
  },

  remove: function () {
    this.el.removeEventListener(GEO_DATA_READY_EVENT, this.bindEvent);
    this.el.sceneEl.removeEventListener('pointermove', this.pointermove);
    this.el.sceneEl.removeEventListener('pointerdown', this.pointerdown);
    this.el.sceneEl.removeEventListener('pointerup', this.pointerup);
  },

  bindEvent: function () {
    this.el.sceneEl.addEventListener('pointermove', this.pointermove.bind(this));
    this.el.sceneEl.addEventListener('pointerdown', this.pointerdown.bind(this));
    this.el.sceneEl.addEventListener('pointerup', this.pointerup.bind(this));
  },

  pointerdown: function (e) {
    if (!this.data.enabled) return;
    this.lastPoint = { x: e.clientX, y: e.clientY };
  },
  pointerup: function (e) {
    if (!this.data.enabled) return;
    const lastPoint = this.lastPoint;
    if (lastPoint.x !== e.clientX || lastPoint.y !== e.clientY) return;
    const mesh = this.getSelectMesh(e);
    if (!mesh) return;
    this.el.emit('geoMap-pointerup', { mesh: mesh });
  },
  pointermove: function (e) {
    if (!this.data.enabled) return;
    this.resetMaterial();

    const mesh = this.getSelectMesh(e);
    if (!mesh) return;
    const material = new THREE[mesh.material[0].type]();
    material.color.copy(new THREE.Color(this.data.color));
    mesh.material[0] = material;
  },
  resetMaterial: function () {
    // 点击前状态
    const extrudeMesh = this.el.getObject3D(extrudeMeshKey);
    if (extrudeMesh && extrudeMesh.children) {
      extrudeMesh.children.forEach(mesh => {
        mesh.material[0] = this.el.components.material.material;
      });
    }
  },
  getSelectMesh: function (e) {
    const extrudeMesh = this.el.getObject3D(extrudeMeshKey);
    // 检测鼠标射线是否选中地图
    const mouse = new THREE.Vector2(
      (e.clientX / window.innerWidth) * 2 - 1,
      -(e.clientY / window.innerHeight) * 2 + 1
    );
    const camera = this.el.sceneEl.camera;
    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(mouse, camera);
    const inObjs = raycaster.intersectObjects([extrudeMesh], true);

    if (inObjs.length > 0) {
      const mesh = inObjs[0].object;
      if (!this.isVisible(mesh)) return;
      return mesh;
    }
  },
  isVisible: function (mesh) {
    const seftVisible = mesh.visible;
    if (!seftVisible) return false;
    if (mesh.parent) return this.isVisible(mesh.parent);
    return true;
  }

});

// 标注
const MARKER_DATA_READY_EVENT = 'marker-data-ready';
const buildingMeshKey = 'geoBuildingMesh';
AFRAME.registerComponent('geo-marker-renderer', {
  dependencies: ['geo-projection', 'material'],

  schema: {
    geoJson: { default: '' },
    parentGeoProjection: {
      default: ''
    },
    point: { default: true },
    pointRadius: {
      default: 0.1
    },
    line: {
      default: false
    },
    linePointNum: { default: 1000 },
    lineDuring: { default: 1 },
    linecolor: {
      type: 'color',
      default: ''
    },
    linewidth: {
      default: 0.01
    },
    lineheight: {
      default: 0.5
    },
    position: {
      type: 'vec3',
      default: new THREE.Vector3(0, 0, 0)
    },
    buildingMixin: { default: 'officeMixin' },
    buildingOffset: {
      type: 'vec3',
      default: new THREE.Vector3(0, 0, 0)
    },
    buildingFont: { default: '#font-STSONG' },
    buildingFontSize: { default: 10 },
    buildingFontColor: { type: 'color', default: '#FFF' },
    buildingFontOpacity: { default: 1 }
  },

  init: function () {
    const el = document.querySelector(this.data.parentGeoProjection);
    el.addEventListener(GEO_DATA_READY_EVENT, e => {
      this.geoJson = JSON.parse(this.data.geoJson);
      this.render();
    });
  },

  update: function (oldData) {
    const data = this.data;

    const diff = AFRAME.utils.diff(oldData, data);
    if (diff) {
      this.geoJson = JSON.parse(data.geoJson);
      if (!this.el.getObject3D(makerMeshKey)) return;
      this.render();
    }
  },

  tick: function (time, timeDelta) {
    this.tickFlyLine(time);
  },

  remove: function () {
    // this.el.removeEventListener(GEO_DATA_READY_EVENT, this.render);
    const obj = this.el.getObject3D(makerMeshKey);
    if (obj) {
      this.el.removeObject3D(makerMeshKey);
    }
  },

  render: function () {
    const data = this.data;
    // 清空
    if (this.el.getObject3D(makerMeshKey)) {
      // 点标注、飞线
      this.el.removeObject3D(makerMeshKey);
      // 建筑模型
      this.el.querySelectorAll('.' + buildingMeshKey).forEach(aEl => {
        this.el.removeChild(aEl);
      });
    }
    const group = new THREE.Group();

    // 计算点坐标
    this.getMarkerVec3();

    // 圆圈标注
    const markerMeshs = this.generateMaker();
    markerMeshs.forEach(marker => { group.add(marker); });

    // 飞线标注
    const flyLineMeshs = this.generateFlyline();
    flyLineMeshs.forEach(line => { group.add(line); });

    // 建筑标注
    this.generateBuilding();

    // 设置偏移
    const vec3 = data.position;
    group.position.set(vec3.x, vec3.y, vec3.z);
    this.el.setObject3D(makerMeshKey, group);
  },

  // 获取标注的坐标列表
  getMarkerVec3: function () {
    const geoJson = this.geoJson;
    const data = this.data;
    const markerList = [];
    const mapEl = document.querySelector(data.parentGeoProjection);
    const projection = mapEl.components['geo-projection'].projection;

    const path = d3.geoPath(projection).pointRadius(data.pointRadius);

    for (let index = 0; index < geoJson.features.length; index++) {
      const aGeoFeatures = geoJson.features[index];
      const centroid = path.centroid(aGeoFeatures);
      const position = new THREE.Vector3(centroid[0], centroid[1], 0);
      markerList.push({
        position: position,
        geoData: aGeoFeatures
      });
    }
    this.markerVec3 = markerList;
    this.el.emit(MARKER_DATA_READY_EVENT, { markerList: markerList });
  },

  // 根据geoJson创建标注点
  generateMaker: function () {
    const markerVec3 = this.markerVec3;
    if (!this.data.point || markerVec3.length <= 0) return [];

    const markerList = [];
    for (let i = 0; i < markerVec3.length; i++) {
      const geometry = new THREE.CircleGeometry(this.data.pointRadius, 64);
      const material = this.el.components.material.material;
      const mesh = new THREE.Mesh(geometry, material);
      const position = markerVec3[i].position;
      mesh.position.set(position.x, position.y, position.z);
      markerList.push(mesh);
    }

    return markerList;
  },

  // 其它标注点向最后一个标注点，发射贝塞尔曲线
  generateFlyline: function () {
    const data = this.data;
    const markerVec3 = this.markerVec3;
    if (!data.line || markerVec3.length <= 2) return [];

    const flyLineMeshs = [];
    const startVec3 = markerVec3[markerVec3.length - 1].position;

    for (let i = 0; i < markerVec3.length - 1; i++) {
      const endVec3 = markerVec3[i].position;
      const middleVec3 = new THREE.Vector3().addVectors(endVec3, startVec3).divideScalar(2).setZ(data.lineheight);
      const curve = new THREE.QuadraticBezierCurve3(
        endVec3,
        middleVec3,
        startVec3
      );
      const points = curve.getPoints(this.data.linePointNum);
      const geometry = new THREE.BufferGeometry().setFromPoints(points);
      // const material = new THREE.LineBasicMaterial({
      //   color: data.linecolor,
      //   linewidth: data.linewidth
      // });
      // const curveObject = new THREE.Line(geometry, material);

      const ver = new Float32Array(points.length);
      for (let i = 0; i < points.length; i++) {
        ver[i] = i;
      }
      geometry.setAttribute('aIndex', new THREE.BufferAttribute(ver, 1));
      const material = new THREE.ShaderMaterial({
        transparent: true,
        depthWrite: false,
        uniforms: {
          uTime: { value: 0 },
          uLength: {
            value: points.length
          },
          uColor: {
            value: new THREE.Color(data.linecolor)
          },
          uScale: {
            value: data.linewidth
          }
        },
        vertexShader: `
            attribute float aIndex;
            uniform float uTime;
            uniform float uLength;
            uniform float uScale;
            varying float vSize;
            void main() {
              vec4 viewPosition = viewMatrix * modelMatrix * vec4(position,1);
              gl_Position = projectionMatrix *  viewPosition;
              vSize = aIndex- uTime;
              if(vSize<=0.0){
                  vSize = vSize + uLength;
              }else{
                vSize = 0.0;
              }
              gl_PointSize = vSize * uScale;
            }
        `,
        fragmentShader: `
          varying float vSize;
          uniform vec3 uColor;
          void main(){
            float distanceToCenter = distance(gl_PointCoord,vec2(0.5));
            float alpha = 1.0 - (distanceToCenter*2.0);
            gl_FragColor = vec4(1,0,0,alpha);
            if(vSize<=0.0){
                gl_FragColor = vec4(1,0,0,0);
            }else{
              // // gl_FragColor = vec4(uColor,1.0);
                gl_FragColor = vec4(uColor,alpha); // 圆点点
            }
          }
        `
      });
      const curveObject = new THREE.Points(geometry, material);
      flyLineMeshs.push(curveObject);
    }
    return flyLineMeshs;
  },

  // 根据时间变化，动态绘制飞线
  tickFlyLine: function (time) {
    if (!this.data.line) return;
    const pointNum = this.data.linePointNum; // 点的总数量
    const during = this.data.lineDuring; // 每N秒一次循环
    const group = this.el.getObject3D(makerMeshKey);
    group && group.children.forEach(mesh => {
      if (mesh.material instanceof THREE.ShaderMaterial) {
        const uTime = (pointNum / during * (time / 1000)) % pointNum;
        mesh.material.uniforms.uTime.value = uTime;
      }
    });
  },

  // 根据geoJson标注建筑模型
  generateBuilding: function () {
    const data = this.data;
    const markerVec3 = this.markerVec3;
    if (markerVec3.length <= 0) return [];

    for (let i = 0; i < markerVec3.length; i++) {
      const position = markerVec3[i].position;
      const name = markerVec3[i].geoData.properties.name;
      // 添加建筑模型
      const el = document.createElement('a-entity');
      el.id = name;
      el.className = buildingMeshKey;
      el.setAttribute('mixin', data.buildingMixin);
      el.setAttribute('position', `${position.x} ${position.y}, ${data.buildingOffset.z}`);
      this.el.appendChild(el);

      // 显示建筑名称
      const aEl = document.createElement('a-troika-text');
      aEl.className = geoTextMeshKey;
      aEl.setAttribute('material', `shader: standard; emissive: ${data.buildingFontColor}; opacity: ${data.buildingFontOpacity}`);
      aEl.setAttribute('font', data.buildingFont);
      aEl.setAttribute('font-size', data.buildingFontSize + '');
      aEl.setAttribute('value', name);
      aEl.setAttribute('position', '0 100 0');
      aEl.setAttribute('rotation', '-45 0 0');
      el.appendChild(aEl);
      const plane = document.createElement('a-plane');
      plane.setAttribute('color', '#bcfd86');
      plane.setAttribute('height', '20');
      plane.setAttribute('width', '55');
      plane.setAttribute('position', '0 0 -0.2');
      aEl.appendChild(plane);
    }
  }
});

const starMeshKey = 'geoStar';
// 星空背景
AFRAME.registerComponent('star', {
  schema: {
    num: { default: 1000 },
    size: { default: 2.5 },
    transparent: { default: true },
    opacity: { default: 0.5 },
    sizeAttenuation: { default: false },
    vertexColors: { default: true },
    vertexColor: { type: 'color', default: '#00ff91' },
    color: { type: 'color', default: '#ffffff' }
  },
  init: function () {
    this.render();
  },
  update: function (oldData) {
    const data = this.data;

    const diff = AFRAME.utils.diff(oldData, data);
    if (diff) {
      this.render();
    }
  },
  remove: function () {
    const obj = this.el.getObject3D(starMeshKey);
    if (obj) {
      this.el.removeObject3D(starMeshKey);
    }
  },
  tick: function (time, delta) {},

  render: function () {
    this.el.getObject3D(starMeshKey) && this.el.removeObject3D(starMeshKey);

    const data = this.data;

    const material = new THREE.PointsMaterial({
      size: data.size,
      transparent: data.transparent,
      opacity: data.opacity,
      vertexColors: data.vertexColors,
      sizeAttenuation: data.sizeAttenuation,
      color: new THREE.Color(data.color).getHex()
    });

    const geometry = new THREE.BufferGeometry();
    const vertices = [];
    const colors = [];
    const range = 500;
    for (let i = 0; i < data.num; i++) {
      const vertex = new THREE.Vector3(
        Math.random() * range - range / 2,
        Math.random() * range - range / 2,
        Math.random() * range - range / 2);
      vertices.push(vertex.x, vertex.y, vertex.z);
      const color = new THREE.Color(data.vertexColor);
      const asHSL = {};
      color.getHSL(asHSL);
      color.setHSL(asHSL.h, asHSL.s, asHSL.l * Math.random());
      colors.push(color.r, color.g, color.b);
    }
    geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
    geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));

    const points = new THREE.Points(geometry, material);
    this.el.setObject3D(starMeshKey, points);
  }
});
