<!--
 * @Author: DQL
 * @Date: 2022-11-30 10:13:40
 * @LastEditors: cxb chenxiaobiao@hongmeng-info.com
 * @LastEditTime: 2023-08-28 17:18:02
 * @Description: file content
-->
<template>
  <div
    ref="chartWrapper"
    v-resize:debounce.500="resizeChart"
    class="dev-o-echart"
  >
    <span class="dev-dw">单位：米</span>
    <div
      id="sqChart"
      style="width: 400px; height: 180px;"
    />
    <div
      v-if="chartData.length===0"
      class="dev-o-echart__empty"
    >
      暂无记录！
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts/core';
import 'echarts-liquidfill';
import resize from 'vue-resize-directive';
import { zhxfService } from './zhxf.service';
export default {
  name: 'SqChart',
  directives: {
    resize
  },
  props: {
    // chartData: {
    //   required: false,
    //   type: Array,
    //   default: function () {
    //     return [];
    //   }
    // },
    code: {
      type: String,
      default: null
    },
    ssbm: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      sqChart: null,
      chartData: [],
      chartOption: {
        color: ['#2ac8fa', '#2afae5', '#fab52a', '#2afaa0'],
        tooltip: {
          show: false,
          backgroundColor: 'red', // 设置透明背景色
          borderColor: 'red',
          textStyle: { color: 'red' },
          alwaysShowContent: false,
          confine: true
          // formatter: function (params) {
          //   return ``<span>1</span>;
          // }
        },

        legend: {
          show: false,
          top: '5%',
          left: 'right',
          orient: 'vertical', // 设置图例的布局为垂直布局
          textStyle: {
            color: 'red'
          }
        },
        graphic: [],
        series: []
      },
      clock: null
    };
  },
  methods: {
    initCanvas () {
      var _canvas = document.getElementById('sqChart');
      var ctx = _canvas.getContext('2d');
      var ratio = window.devicePixelRatio || 1;
      _canvas.style.width = _canvas.width + 'px';
      _canvas.style.height = _canvas.height + 'px';
      _canvas.width = _canvas.width * ratio;
      _canvas.height = _canvas.height * ratio;
      ctx.scale(ratio, ratio);
    },
    /**
     * @description: 图表尺寸重新绘制
     * @return {*}
     */
    resizeChart () {
      if (this.sqChart) {
        this.sqChart.resize();
      }
    },
    /**
     * 获取后端报表数据
     */
    getChartData () {
      console.log('获取报表数据');
      const _this = this;
      zhxfService.queryGwmdyl(this.code, this.ssbm).then(resp => {
        _this.chartData = resp.data.values;
        _this.setEchart();
      });
    },
    clearClock () {
      clearInterval(this.clock);
      this.clock = null;
    },
    addClock () {
      if (this.code && !this.clock) {
        this.getChartData();
        this.clock = setInterval(this.getChartData, 30 * 1000);
      }
    },
    /**
    /**
     * @description: 图表初始化
     * @return {*}
     */
    setEchart () {
      // this.initCanvas();
      this.sqChart = echarts.init(document.getElementById('sqChart'));
      this.sqChart.setOption(this.chartOption);
      this.initEchartData(this.getLatestDataByGroup(this.chartData));
    },
    /**
     * @description: 图表内容重新绘制
     * @return {*}
     */
    chartChange () {
      this.sqChart.clear();
      this.chartOption.series = [];
      this.getChartData();
    },
    getDataTemplate () {
      return {
        type: 'liquidFill',
        name: '', // 系列名称，用于tooltip的显示，legend 的图例筛选
        data: [],
        shape: 'linear',
        radius: '90%',
        center: [], // 位置
        amplitude: 5, // 振幅
        waveLength: '200%', // 波长
        color: ['#2ac8fa'],
        itemStyle: {
          opacity: 1
        },
        backgroundStyle: {
          color: 'transparent',
          borderColor: '#505b75',
          borderWidth: 1,
          shadowBlur: 0
        },
        outline: {
          show: false
        },
        label: {
          show: true,
          formatter: function (param) {
            const text = param.seriesName + ':  ' + param.data.sz + '米';
            if (param.data.ztms.indexOf('高位') > -1) {
              return '高位报警 \n\n ' + text;
            };
            if (param.data.ztms.indexOf('低位') > -1) {
              return '低位报警 \n\n ' + text;
            };
            return text;
          },
          fontSize: 16,
          color: '#fff'
        }
      };
    },
    initPosition (length) {
      if (length === 1) {
        return [['50%', '50%']];
      } else if (length === 2) {
        return [['25%', '50%'], ['75%', '50%']];
      }
      return [];
    },
    initGraphPosition (length) {
      if (length === 1) {
        return [['48%', '90%']];
      } else if (length === 2) {
        return [['18%', '90%'], ['57%', '90%']];
      }
      return [];
    },
    /**
     * @description: 初始化涂标数据
     * @return {*}
     * @param {*} _datas 数据
     */
    initEchartData (_datas) {
      const _this = this;

      const dataSize = _datas.length;
      // 初始化水球应该放置的位置
      const position = _this.initPosition(dataSize);

      // 初始化图形应该放置的位置
      const graphPosition = _this.initGraphPosition(dataSize);

      // 组装数据
      const _serieaData = [];
      _datas.forEach((item, index) => {
        const _data = _this.getDataTemplate();
        const gwbj = item.ztms.indexOf('高位报警') > -1;
        const dwbj = item.ztms.indexOf('低位报警') > -1;
        const value = item.value;
        const value2 = value - 0.1;
        // value值减0.1

        _data.name = item.azwz;
        _data.data = [item, gwbj ? value2 : null];
        _data.center = position[index];
        // 如果只有一个，则填充满
        if (dataSize === 1) {
          _data.shape = 'container';
        }
        // 报警状态
        if (gwbj) {
          _data.color = ['#f5222d'];// 水池红色
          _data.amplitude = 15;// 振幅扩大
          _data.period = 1500;
        }
        if (dwbj) {
          _data.color = ['#86f69c'];
          _data.amplitude = 2;
        }
        _serieaData.push(_data);
      });

      // 组装图形数据
      const _graphData = [];
      _datas.forEach((item, index) => {
        let text = '';
        if (item.length !== undefined && item.length !== null) {
          const height = item.height.toString().padStart(4, ' ');
          const width = item.width.toString().padStart(4, ' ');
          const length = item.length.toString().padStart(4, ' ');
          text = `高：${height}米  宽：${width}米  长：${length}米`;
          // 计算水池的容积
          const volume = (item.height * item.width * item.length).toFixed(2);
          text = text + '\n容积： ' + volume + ' 立方米';
        }
        const _data = {
          type: 'text',
          left: graphPosition[index][0],
          top: graphPosition[index][1],
          z: 10,
          style: {
            text: text,
            fill: '#fff',
            font: '16px Microsoft YaHei'
            // width: 100, // 限制文本的宽度
            // overflow: 'break' // 当文本超出宽度时换行
          }
        };
        _graphData.push(_data);
      });

      _this.chartOption.series = _serieaData;
      _this.chartOption.graphic = _graphData;
      _this.sqChart.setOption(_this.chartOption);
    },
    // 过滤按照 "azwz" 分组的数据，找到 "cjsj" 最新的对象，并计算百分比
    getLatestDataByGroup (data) {
      // 创建一个映射，键是 "azwz"，值是对象数组
      const groups = data.reduce((map, item) => {
        const key = item.bjwh + item.azwz;
        if (!map[key]) {
          map[key] = [];
        }
        map[key].push(item);
        return map;
      }, {});

      // 对于每个分组，找到 "cjsj" 最新的对象，并计算百分比
      const result = [];
      for (const key in groups) {
        const group = groups[key];
        group.sort((a, b) => b.cjsj - a.cjsj); // 按 "cjsj" 降序排序
        const latest = group[0]; // 取 "cjsj" 最新的对象
        // 计算百分比
        const height = latest.height ? latest.height : 10;
        const value = ((parseFloat(latest.sz) / parseFloat(height))).toFixed(2); // 计算百分比并保留两位小数
        latest.value = value;

        // 模拟高位报警
        // if (latest.azwz === '天面水池') {
        //   latest.bjzt = '20';
        //   latest.ztms = '低位报警';
        // } else {
        //   latest.bjzt = '20';
        //   latest.ztms = '高位报警';
        // }
        result.push(latest);
      }
      return result;
    },
    handleResize () {
      this.sqChart.resize();
    }
  },
  watch: {
    code (newVal, oldVal) {
      this.clearClock();
      if (newVal) {
        this.chartChange();
        this.addClock();
      }
    }
  },
  mounted () {
    console.log('122');
    this.setEchart();
    // this.getChartData();
    this.addClock();
    // window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy () {
    // window.removeEventListener('resize', this.handleResize);
    this.$refs.chartWrapper.removeEventListener('resize', this.sqChart.resize);
    this.clearClock();
  }
};
</script>
<style lang="less" scoped>
.dev-dw{
  position: absolute;
  top: -45px;
  right: 10px;
  font-size: 15px;
  color: #fff;
}

.dev-o-echart {
  position: relative;
}

.dev-o-echart__empty {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  font-size: 23px;
  color: #c0c4cc;
  text-align: center;
  background-color: #071530;
}
</style>
