/*
 * @Author: DQL
 * @Date: 2020-04-10 08:08:34
 * @LastEditors: DQL
 * @LastEditTime: 2020-04-10 08:46:41
 * @Description: 自定义过滤器
 */

import Vue from 'vue';
import store from './store';

/**
 * 系统枚举转义
 * @param  enumKey 键值
 * @param  category 枚举分组
 * @return 枚举值
 *
 * 使用 eg. {{'PX' | enums('DEMAND_TYPE')}}
 */
Vue.filter('apiEnums', function (id, type, _api) {
  if (_api) {
    // eslint-disable-next-line no-unused-vars
    const apiEnums = store.state.apiEnums[_api] || {};
    if (!apiEnums) return id;
    // eslint-disable-next-line no-eval
    const enums = eval('apiEnums.' + type);
    if (!enums) {
      console.warn('没有此分类枚举', type);
      return id;
    };
    if (enums['ENUM_KEY_' + id] != null) {
      return enums['ENUM_KEY_' + id].text;
    } else {
      return id;
    }
  } else {
  // eslint-disable-next-line no-unused-vars
    let apiEnums = {};
    Object.keys(store.state.apiEnums).forEach(key => {
      apiEnums = { ...apiEnums, ...store.state.apiEnums[key] };
    });
    // eslint-disable-next-line no-eval
    const enums = eval('apiEnums.' + type);
    if (!enums) {
      console.warn('没有此分类枚举', type);
      return id;
    }
    if (enums['ENUM_KEY_' + id] != null) {
      return enums['ENUM_KEY_' + id].text;
    } else {
      return id;
    }
  }
});
