<template>
  <div style="height: 100%;">
    <div class="npl-model">
      <img
        style="height: 100%;"
        src="/3DModel/npl/women.png"
      >
    </div>

    <!-- 左边 -->
    <div class="npl-graph npl-graph-left">
      <npl-dialog ref="NplDialog" />
    </div>

    <!-- 中间 -->
    <div class="npl-graph npl-graph-center">
      <div
        class="npl-echart"
        style="height: 49%;"
      >
        <div class="npl-schart__header u-font-PFSC">
          <div class="npl-schart__title">
            管网压力
          </div>
        </div>
        <div class="npl-schart__body">
          <gwyl-chart
            ref="gwylChart"
            :style="'width:100%;height: 85%;'"
            :code="selDw.code"
            ssbm="A53,A54,A68"
            class="dev-c-dCard__body"
          />
        </div>
      </div>
      <div
        class="npl-echart"
        style="height: 49%;"
      >
        <div class="npl-schart__header u-font-PFSC">
          <div class="npl-schart__title">
            水位监控
          </div>
        </div>
        <div class="npl-schart__body">
          <!-- <swjk-chart
            ref="swjkChart"
            :style="'width:100%;height: 85%;'"
            :code="selDw.code"
            ssbm="A35"
            class="dev-c-dCard__body"
          /> -->
          <sq-chart
            ref="swChart"
            :code="selDw.code"
            ssbm="A35"
            class="dev-c-dCard__body"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NplDialog from './npl-dialog.vue';
// import SwjkChart from '../chart/swjkChart.vue';
import GwylChart from '../chart/gwylChart.vue';
import SqChart from '../chart/sqChart.vue';
import { zhxfService } from './../chart/zhxf.service';

export default {
  name: 'NplXfswg',
  components: {
    NplDialog,
    // SwjkChart,
    GwylChart,
    SqChart
  },
  props: {
    selDw: {
      type: Object,
      default: () => { return null; }
    },
    selQuest: {
      type: String,
      default: () => { return null; }
    }
  },
  data () {
    return {

    };
  },
  mounted () {
    const _this = this;
    Promise.all([
      zhxfService.queryBjxx({ sslx: 'GS001', pageSize: 50, pageNo: 1, ssdw: this.selDw.code }),
      zhxfService.queryGwmdyl(_this.selDw.code, 'A35')
    ]).then(result => {
      const bjxxs = result[0].data.rows;
      const chartData = result[1].data.values;
      // 拼接设施的安装位置
      const wzxx = bjxxs.reduce((total, item) => {
        // const sz = `${chartData && chartData.length > 0 ? chartData[0].sz : 0}`;
        const oneData = chartData.find(el => { return el.azwz === item.azwz; });
        const sz = oneData ? oneData.sz : '0';
        total += `，${item.azwz}#{bg}${sz}#{ed}米`; return total;
      }, '');
      const answer = `${_this.selDw.name}有#{bg}${bjxxs.length}#{ed}个消防水池；当前监测到的水位信息如下${wzxx}。`;
      _this.$refs.NplDialog.setText(_this.selQuest, answer.replace(/#{bg}/g, '<span style="color: #08f7f7;">').replace(/#{ed}/g, '</span>'));
      _this.$parent.playVoice(answer.replace(/#{bg}/g, '').replace(/#{ed}/g, '')); // 播报语音
    });
  }
};
</script>

<style scoped lang="scss">
@import './npl.scss';

.npl-model {
  float: left;
  box-sizing: border-box;
  width: 30%;
  height: 100%;
  padding-right: 3%;
  text-align: right;
}

.npl-graph {
  float: left;
  box-sizing: border-box;
  height: 100%;
  margin: 0 5px;

  &-left{
    width: 22%;
    padding: 25px 25px 20px 30px;
    border: solid 1px #2667ae;
    background-image: linear-gradient(269deg, rgba(14, 33, 70, 0.24) 0%, rgba(12, 29, 63, 0.64) 10%, rgba(0, 9, 28, 0.72) 99%);
  }

  &-center{
    width: 28%;
  }

  &-right{
    width: 28%;
  }
}

.npl-schart__body {
  width: 100%;
  height: 100%;
}
</style>
