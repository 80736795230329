<template>
  <div style="height: 100%;">
    <div class="npl-model">
      <img
        style="height: 100%;"
        src="/3DModel/npl/women.png"
      >
    </div>

    <!-- 左边 -->
    <div class="npl-graph npl-graph-left">
      <npl-dialog ref="NplDialog" />
      <div class="npl-schart npl-sli-wrap">
        <div class="npl-schart__header u-font-PFSC">
          <div class="npl-schart__title">
            所有联网单位的检测情况
          </div>
        </div>
        <div class="npl-schart__body">
          <ul class="npl-sli">
            <li class="npl-sli__item">
              <span class="npl-sli__icon"><img src="/3DModel/npl/icon-0.png"></span>
              <span class="npl-sli__label">当月保养计划</span>
              <span class="npl-sli__num">{{ dw.zs }}</span>
            </li>
            <li class="npl-sli__item">
              <span class="npl-sli__icon"><img src="/3DModel/npl/icon-3.png"></span>
              <span class="npl-sli__label">当月已完成计划</span>
              <span class="npl-sli__num">{{ dw.ywc }}</span>
            </li>
            <li class="npl-sli__item">
              <span class="npl-sli__icon"><img src="/3DModel/npl/icon-3.png"></span>
              <span class="npl-sli__label">当月未完成计划</span>
              <span class="npl-sli__num">{{ dw.wwc }}</span>
            </li>
          </ul>
          <ul
            v-show="dw.yhwt"
            class="npl-sli"
          >
            <li class="npl-sli__item_red">
              <span class="npl-sli__icon" />
              <span class="npl-sli__label_red"><img src="/3DModel/npl/icon-7.png">当月未完成保养计划的单位</span>
              <span class="npl-sli__num_red">
                {{ dw.yhwt }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- 右边 -->
    <div class="npl-graph npl-graph-center">
      <div
        class="npl-echart"
        style="height: 49%;"
      >
        <div class="npl-schart__header u-font-PFSC">
          <div class="npl-schart__title">
            近半年保养计划数
          </div>
        </div>
        <div
          ref="graphXccs"
          :style="'height: 100%;width:100%'"
        />
      </div>
      <div
        class="npl-echart"
        style="height: 49%;"
      >
        <div class="npl-schart__header u-font-PFSC">
          <div class="npl-schart__title">
            近半年未完成保养计划数
          </div>
        </div>
        <div
          ref="graphXcxzs"
          :style="'height: 100%;width:100%'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import NplDialog from './npl-dialog.vue';

export default {
  name: 'NplXfby',
  components: {
    NplDialog
  },
  props: {
    dwList: {
      type: Array,
      default: () => {
        return [
        ];
      }
    },
    selDw: {
      type: Object,
      default: () => { return null; }
    },
    selQuest: {
      type: String,
      default: () => { return null; }
    }
  },
  data () {
    return {
      dw: {
        zs: 0,
        ywc: 0,
        wwc: 0,
        yhwt: ''
      },
      // 近半年保养计划数柱状图
      graphXccs: null,
      graphXccsData: [['月份', '广州物资大夏', '鸿蒙消防实验室'],
        ['6月份', 2, 2],
        ['7月份', 3, 0],
        ['8月份', 4, 1],
        ['9月份', 2, 1],
        ['10月份', 7, 8],
        ['11月份', 3, 0],
        ['12月份', 1, 0]
      ],
      // 近半年未完成保养计划数柱状图
      graphXcxzs: null,
      graphXcxzsData: [['月份', '广州物资大夏', '鸿蒙消防实验室'],
        ['6月份', 1, 2],
        ['7月份', 2, 0],
        ['8月份', 3, 1],
        ['9月份', 1, 1],
        ['10月份', 6, 8],
        ['11月份', 2, 0],
        ['12月份', 1, 0]
      ]
    };
  },
  methods: {
    initGraphXccs () {
      this.graphXccs = this.$echarts.init(this.$refs.graphXccs);
      const option = {
        legend: {
          left: 30,
          top: 10,
          textStyle: { color: '#ffffff', fontSize: 12 },
          itemStyle: { borderType: 'dashed' }
        },
        tooltip: {},
        dataset: {
          source: [

          ]
        },
        xAxis: {
          type: 'category'
          // boundaryGap: false
        },
        yAxis: {
          axisLabel: { show: false },
          splitLine: { lineStyle: { color: '#016e9a', opacity: 0.5 } }
        },
        series: [
          { type: 'bar', label: { show: true, position: 'top', color: '#ffffff', fontSize: 12 } },
          { type: 'bar', label: { show: true, position: 'top', color: '#ffffff', fontSize: 12 } }
        ],
        // color: ['#f94848', '#f9dd25'],
        textStyle: {
          fontFamily: 'Microsoft YaHei',
          fontSize: 12,
          color: '#fff'
        }
      };
      this.graphXccs.setOption(option);
    },
    updateGraphXccs () {
      const data = this.graphXccsData;
      const opt = this.graphXccs.getOption();
      opt.dataset.source = data;
      this.graphXccs.setOption({
        dataset: {
          source: data
        }
      });
    },
    initGraphXcxzs () {
      this.graphXcxzs = this.$echarts.init(this.$refs.graphXcxzs);
      const option = {
        legend: {
          left: 30,
          top: 10,
          textStyle: { color: '#ffffff', fontSize: 12 },
          itemStyle: { borderType: 'dashed' }
        },
        tooltip: {},
        dataset: {
          source: [

          ]
        },
        xAxis: {
          type: 'category'
          // boundaryGap: false
        },
        yAxis: {
          axisLabel: { show: false },
          splitLine: { lineStyle: { color: '#016e9a', opacity: 0.5 } }
        },
        series: [
          { type: 'bar', label: { show: true, position: 'top', color: '#ffffff', fontSize: 12 } },
          { type: 'bar', label: { show: true, position: 'top', color: '#ffffff', fontSize: 12 } }
        ],
        // color: ['#f94848', '#f9dd25'],
        textStyle: {
          fontFamily: 'Microsoft YaHei',
          fontSize: 12,
          color: '#fff'
        }
      };
      this.graphXcxzs.setOption(option);
    },
    updateGraphXcxzs () {
      const data = this.graphXcxzsData;
      const opt = this.graphXcxzs.getOption();
      opt.dataset.source = data;
      this.graphXcxzs.setOption({
        dataset: {
          source: data
        }
      });
    },
    updateDw () {
      this.dw.zs = this.graphXccsData[this.graphXccsData.length - 1]
        .reduce((total, item, index) => {
          if (index === 0) return total;
          return total + item;
        }, 0);

      this.dw.wwc = this.graphXcxzsData[this.graphXcxzsData.length - 1]
        .reduce((total, item, index) => {
          if (index === 0) return total;
          return total + item;
        }, 0);

      this.dw.ywc = this.dw.zs - this.dw.wwc;

      this.dw.yhwt = this.graphXcxzsData[this.graphXcxzsData.length - 1]
        .reduce((total, item, index) => {
          if (index === 0) return total;
          if (item !== 0) {
            return total + (total === '' ? '' : '、') + this.graphXcxzsData[0][index];
          }
          return total;
        }, '');
    }
  },
  mounted () {
    this.initGraphXccs();
    this.initGraphXcxzs();

    // Promise.all([
    //   virtualService.queryZbry(this.selDw.code),
    //   virtualService.queryHjxx(this.selDw.code)
    // ]).then(result => {
    this.updateGraphXccs();
    this.updateGraphXcxzs();
    this.updateDw();
    let answer = `当月总计有#{bg}${this.dw.zs}#{ed}个保养计划，其中已完成#{bg}${this.dw.ywc}#{ed}个，未完成${this.dw.wwc}个。`;
    if (this.dw.yhwt) answer += `其中#{bg}${this.dw.yhwt}#{ed}当月未完成保养计划，存在安全隐患`;
    this.$refs.NplDialog.setText(this.selQuest, answer.replace(/#{bg}/g, '<span class="highLight">').replace(/#{ed}/g, '</span>'));
    this.$parent.playVoice(answer.replace(/#{bg}/g, '').replace(/#{ed}/g, '')); // 播报语音
    // });

    // 图表自适应宽度
    const gRize = function () {
      this.graphXccs.resize();
      this.graphXcxzs.resize();
    };
    window.addEventListener('resize', gRize.bind(this), false);
  },
  destroyed () {
    this.graphXccs.dispose();
    this.graphXcxzs.dispose();
  }
};
</script>

<style scoped lang="scss">
@import './npl.scss';

.npl-model {
  float: left;
  box-sizing: border-box;
  width: 30%;
  height: 100%;
  padding-right: 3%;
  text-align: right;
}

.npl-graph {
  float: left;
  box-sizing: border-box;
  height: 100%;
  margin: 0 5px;

  &-left{
    width: 22%;
    padding: 25px 25px 20px 30px;
    border: solid 1px #2667ae;
    background-image: linear-gradient(269deg, rgba(14, 33, 70, 0.24) 0%, rgba(12, 29, 63, 0.64) 10%, rgba(0, 9, 28, 0.72) 99%);
  }

  &-center{
    width: 28%;
  }

  &-right{
    width: 28%;
  }
}
</style>
