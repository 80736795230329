<template>
  <div style="height: 100%;">
    <div class="npl-model">
      <img
        style="height: 100%;"
        src="/3DModel/npl/women.png"
      >
    </div>

    <!-- 左边 -->
    <div class="npl-graph npl-graph-left">
      <npl-dialog ref="NplDialog" />
      <div class="npl-schart npl-sli-wrap">
        <div class="npl-schart__header u-font-PFSC">
          <div class="npl-schart__title">
            {{ selDw?selDw.name:'' }}
          </div>
        </div>
        <div class="npl-schart__body">
          <ul class="npl-sli">
            <li class="npl-sli__item">
              <span class="npl-sli__icon"><img src="/3DModel/npl/icon-3.png"></span>
              <span class="npl-sli__label">消防设施总计</span>
              <span class="npl-sli__num">{{ dw.xfssZj }}</span>
            </li>
            <li class="npl-sli__item">
              <span class="npl-sli__icon"><img src="/3DModel/npl/icon-0.png"></span>
              <span class="npl-sli__label">正常运行</span>
              <span class="npl-sli__num">{{ dw.xfssZc }}</span>
            </li>
            <li class="npl-sli__item">
              <span class="npl-sli__icon"><img src="/3DModel/npl/icon-4.png"></span>
              <span class="npl-sli__label">故障设施</span>
              <span class="npl-sli__num">{{ dw.xfssGz }}</span>
            </li>
            <li class="npl-sli__item">
              <span class="npl-sli__icon"><img src="/3DModel/npl/icon-5.png"></span>
              <span class="npl-sli__label">已报修</span>
              <span class="npl-sli__num">{{ dw.xfssYx }}</span>
            </li>
            <li class="npl-sli__item">
              <span class="npl-sli__icon"><img src="/3DModel/npl/icon-6.png"></span>
              <span class="npl-sli__label">未报修</span>
              <span class="npl-sli__num">{{ dw.xfssWx }}</span>
            </li>
            <li class="npl-sli__item">
              <span class="npl-sli__icon"><img src="/3DModel/npl/icon-1.png"></span>
              <span class="npl-sli__label">今日报警</span>
              <span class="npl-sli__num">{{ dw.bj }}</span>
            </li>
            <li class="npl-sli__item">
              <span class="npl-sli__icon"><img src="/3DModel/npl/icon-2.png"></span>
              <span class="npl-sli__label">误报</span>
              <span class="npl-sli__num">{{ dw.wb }}</span>
            </li>
            <li class="npl-sli__item">
              <span class="npl-sli__icon"><img src="/3DModel/npl/icon-8.png"></span>
              <span class="npl-sli__label">消防负责人</span>
              <span class="npl-sli__num">{{ dw.xffzr }}</span>
            </li>
            <li class="npl-sli__item">
              <span class="npl-sli__icon"><img src="/3DModel/npl/icon-9.png"></span>
              <span class="npl-sli__label">当前值班人员</span>
              <span class="npl-sli__num">{{ dw.zbry }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- 中间 -->
    <div class="npl-graph npl-graph-center">
      <div
        class="npl-echart"
        style="height: 32%;"
      >
        <div class="npl-schart__header u-font-PFSC">
          <div class="npl-schart__title">
            消防设施
          </div>
        </div>
        <div class="npl-schart__lab">
          <div
            v-for="item in sbData"
            :key="item.name"
          >
            {{ item.value }}个{{ item.name=='正常'?'正常':'故障' }}
            <el-progress
              :text-inside="true"
              :stroke-width="20"
              :percentage="item.pre"
              :color="item.name=='正常'?'#47cefc':'#ff3801'"
            />
          </div>
        </div>
        <div
          ref="graphSb"
          :style="'float:left;width:50%;height: 180px;'"
        />
      </div>

      <div
        class="npl-echart"
        style="height: 32%;"
      >
        <div class="npl-schart__header u-font-PFSC">
          <div class="npl-schart__title">
            今日报警
          </div>
        </div>
        <div class="npl-schart__lab">
          <div
            v-for="item in jrjbList"
            :key="item.name"
          >
            {{ item.value }}个{{ item.name=='正常'?'正常':'误报' }}
            <el-progress
              :text-inside="true"
              :stroke-width="20"
              :percentage="item.pre"
              :color="item.name=='正常'?'#f94848':'#ffda47'"
            />
          </div>
        </div>
        <div
          ref="graphJrjb"
          :style="'float:left;width:50%;height: 180px;'"
        />
      </div>
      <div
        class="npl-echart"
        style="height: 32%;"
      >
        <div class="npl-schart__header u-font-PFSC">
          <div class="npl-schart__title">
            近一周的报警统计
          </div>
        </div>
        <div
          ref="graphYzjb"
          :style="'height: 100%;width:100%'"
        />
      </div>
    </div>

    <!-- 右边 -->
    <div class="npl-graph npl-graph-right">
      <div class="npl-schart__header u-font-PFSC">
        <div class="npl-schart__title">
          值班人员
        </div>
      </div>
      <div class="c-schart__body">
        <div class="npl-study">
          <div class="npl-study__img">
            <img
              src="/images/screen/user.png"
              :alt="zb.name"
            >
          </div>
          <ul class="npl-study__body">
            <li class="npl-study__item">
              <span class="npl-study__label">姓名：</span>
              <span class="npl-study__content">{{ zb.name }}</span>
            </li>
            <li class="npl-study__item">
              <span class="npl-study__label">手  机 号：</span>
              <span class="npl-study__content">{{ zb.tel }}</span>
            </li>
            <li class="npl-study__item">
              <span class="npl-study__label">证    书：</span>
              <span class="npl-study__content">{{ zb.zyzg }}</span>
            </li>
            <li class="npl-study__item">
              <span class="npl-study__label">证书编号：</span>
              <span class="npl-study__content">{{ zb.zsbh }}</span>
            </li>
            <li class="npl-study__item">
              <span class="npl-study__label">在岗时间：</span>
              <span class="npl-study__content">{{ zb.zgsj?zb.zgsj:0 }}小时</span>
            </li>
          </ul>
        </div>
      </div>
      <div
        v-show="zb.zjdzbUrl"
        class="npl-study"
      >
        <img
          :src="zb.zjdzbUrl"
          class="npl-study__zs"
        >
      </div>
    </div>
  </div>
</template>

<script>
import NplDialog from './npl-dialog.vue';
import { virtualService } from '../virtual.service';

import config from '@/config';
const { iffApi } = config.ctx;

export default {
  name: 'NplDwxf',
  components: {
    NplDialog
  },
  props: {
    selDw: {
      type: Object,
      default: () => { return null; }
    },
    selQuest: {
      type: String,
      default: () => { return null; }
    }
  },
  data () {
    return {
      dw: {},
      // 设备数量饼状图
      graphSb: null,
      sbData: [
        { value: 0, pre: 0, name: '故障' },
        { value: 0, pre: 0, name: '正常' }
      ],
      // 今日警报饼状图
      graphJrjb: null,
      jrjbList: [
        { value: 0, name: '正常' },
        { value: 0, name: '误报' }
      ],
      // 一周警报柱状图
      graphYzjb: null,
      // 未报修故障数据
      wbxList: [
        { id: 1, sbbh: '1-01-001', sbmc: '感烟探测器', sbwz: '一楼大堂', gzsj: '2022-08-12' },
        { id: 2, sbbh: '1-02-011', sbmc: '感烟探测器', sbwz: '二楼茶水间', gzsj: '2022-08-12' },
        { id: 3, sbbh: '1-03-021', sbmc: '感温探测器', sbwz: '三楼大设备房', gzsj: '2022-09-12' }
      ],
      // 隐患问题数据
      wtList: [
        { id: 1, wtms: '一楼大堂的感烟探测器故障', wtdj: '低', fssj: '2022-08-12' },
        { id: 2, wtms: '一楼大堂的感烟探测器故障', wtdj: '低', fssj: '2022-08-13' },
        { id: 3, wtms: '一楼大堂的感烟探测器故障', wtdj: '低', fssj: '2022-09-12' },
        { id: 4, wtms: '八月份未进行消防设施维保', wtdj: '中', fssj: '2022-09-01' }
      ],
      zb: {
        // name: '黄光健',
        // tel: '13288679012',
        // zyzg: '四级消防设施操作员',
        // zsbh: '1936003008400373',
        // zgsj: '6小时24分'
      }
    };
  },
  methods: {
    initGraphSb () {
      this.graphSb = this.$echarts.init(this.$refs.graphSb);
      const option = {
        title: {
          text: '消防设施总数',
          textStyle: { color: '#ffffff', fontSize: 12 },
          top: 20,
          left: '62%'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: { show: false },
        color: ['#ff3801', '#47cefc'],
        series: [
          {
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: true,
                position: 'center',
                color: '#fff',
                formatter: '{total|X}{active|个}\n{active|总数}',
                rich: {
                  total: {
                    fontSize: 24,
                    fontFamily: 'MicrosoftYaHei-Bold',
                    color: '#00ffff'
                  },
                  active: {
                    fontFamily: 'AdobeHeitiStd-Regular',
                    fontSize: 12,
                    color: '#ffffff'
                    // lineHeight: 30
                  }
                }
              }
            },
            labelLine: {
              show: false
            }
          }
        ]
      };
      this.graphSb.setOption(option);
    },
    updateGraphSb () {
      const sbData = [
        { value: this.dw.xfssGz, name: '故障' },
        { value: this.dw.xfssZc, name: '正常' }];
      const total = sbData.reduce((total, item) => { total += item.value; return total; }, 0);
      sbData.forEach(element => {
        element.pre = !total || total === 0 ? 0 : parseFloat((element.value / total * 100).toFixed(2));
      });
      this.sbData = sbData;
      this.graphSb.setOption({
        series: [{
          data: sbData,
          label: {
            normal: { formatter: '{total|' + total + '}{active|个}\n{active|总数}' }
          }
        }
        ]
      });
    },
    initGraphJrjb () {
      this.graphJrjb = this.$echarts.init(this.$refs.graphJrjb);
      const option = {
        title: {
          text: '今日报警总数',
          textStyle: { color: '#ffffff', fontSize: 12 },
          top: 20,
          left: '62%'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: { show: false },
        color: ['#ffda47', '#f94848'],
        series: [
          {
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: true,
                position: 'center',
                color: '#fff',
                formatter: '{total|X}{active|个}\n{active|总数}',
                rich: {
                  total: {
                    fontSize: 24,
                    fontFamily: 'MicrosoftYaHei-Bold',
                    color: '#00ffff'
                  },
                  active: {
                    fontFamily: 'AdobeHeitiStd-Regular',
                    fontSize: 12,
                    color: '#ffffff'
                    // lineHeight: 30
                  }
                }
              }
            },
            labelLine: {
              show: false
            }
          }
        ]
      };
      this.graphJrjb.setOption(option);
    },
    updateGraphJrjb () {
      const jrjbList = [
        { value: this.dw.wb, name: '误报' },
        { value: this.dw.bj - this.dw.wb, name: '正常' }
      ];
      const total = jrjbList.reduce((total, item) => { total += item.value; return total; }, 0);
      jrjbList.forEach(element => {
        element.pre = !total || total === 0 ? 0 : parseFloat((element.value / total * 100).toFixed(2));
      });
      this.jrjbList = jrjbList;
      this.graphJrjb.setOption({
        series: [{
          data: jrjbList,
          label: {
            normal: { formatter: '{total|' + total + '}{active|个}\n{active|总数}' }
          }
        }
        ]
      });
    },
    initGraphYzjb () {
      this.graphYzjb = this.$echarts.init(this.$refs.graphYzjb);
      const option = {
        legend: {
          left: 30,
          top: 10,
          textStyle: { color: '#ffffff', fontSize: 12 },
          itemStyle: { borderType: 'dashed' }
        },
        tooltip: {},
        dataset: {
          source: [

          ]
        },
        xAxis: {
          type: 'category'
          // boundaryGap: false
        },
        yAxis: {
          axisLabel: { show: false },
          splitLine: { lineStyle: { color: '#016e9a', opacity: 0.5 } }
        },
        series: [
          { type: 'bar', label: { show: true, position: 'top', color: '#ffffff', fontSize: 12 } },
          { type: 'bar', label: { show: true, position: 'top', color: '#ffffff', fontSize: 12 } }
        ],
        color: ['#f94848', '#f9dd25'],
        textStyle: {
          fontFamily: 'Microsoft YaHei',
          fontSize: 12,
          color: '#fff'
        }
      };
      this.graphYzjb.setOption(option);
    },
    updateGraphYzjb () {
      virtualService.getHjxxByWeek(this.selDw.code).then(resp => {
        const data = [['bjrq', '报警总数', '误报数']];
        resp.data.forEach(item => {
          const dataArr = item.d.split('-');
          data.push([dataArr[1] + '.' + dataArr[2], item.jrhj, item.wbhj]);
        });
        const opt = this.graphYzjb.getOption();
        opt.dataset.source = data;
        this.graphYzjb.setOption({
          dataset: {
            source: data
          }
        });
      });
    }
  },
  mounted () {
    this.initGraphSb();
    this.initGraphJrjb();
    Promise.all([
      virtualService.queryZbry(this.selDw.code),
      virtualService.getHjxxByToday(this.selDw.code)
    ]).then(result => {
      this.zb = result[0].data[0] ? result[0].data[0] : {};
      const hjxx = result[1].data;
      if (this.zb.zjdzb) {
        this.zb.zjdzbUrl = `${iffApi}/common/image/${this.zb.zjdzb}`;
      }
      const dw = {
        bj: hjxx && hjxx.jrhj ? hjxx.jrhj : 0,
        wb: hjxx && hjxx.wbhj ? hjxx.wbhj : 0,
        xfssZj: this.selDw ? this.selDw.health + this.selDw.error + this.selDw.standby + this.selDw.qt : 0, // 消防设施总数
        xfssZc: this.selDw ? this.selDw.health + this.selDw.error + this.selDw.qt : 0, // 正常设施总数
        xfssGz: this.selDw ? this.selDw.standby : 0, // 故障设施总数
        xfssYx: this.selDw ? this.selDw.gzybx : 0, // 已修设施总数
        xfssWx: this.selDw ? this.selDw.gzwbx : 0, // 未修设施总数
        xffzr: this.selDw && this.selDw.xfaqzrrmc ? `${this.selDw.xfaqzrrmc ? this.selDw.xfaqzrrmc : ''}${this.selDw.xfaqzrrdh ? `（${this.selDw.xfaqzrrdh}）` : ''}` : '',
        zbry: this.selDw && this.selDw.zbry ? `${this.selDw.zbry ? this.selDw.zbry : ''}${this.selDw.zbryDh ? `（${this.selDw.zbryDh}）` : ''}` : ''
      };
      this.dw = dw;
      const answer = `当前${this.selDw ? this.selDw.name : ''}总计有#{bg}${this.dw.xfssZj}#{ed}个消防设施，其中正常运行#{bg}${this.dw.xfssZc}#{ed}个，故障设施#{bg}${this.dw.xfssGz}#{ed}个，已报修#{bg}${this.dw.xfssYx}#{ed}个，#{bg}${this.dw.xfssWx}#{ed}个还未报修。
                      今日报警信号#{bg}${this.dw.bj}#{ed}个，其中#{bg}${this.dw.wb}#{ed}个是误报，已填报消警台账。
                      当前值班人员是#{bg}${this.selDw && this.selDw.zbry ? this.selDw.zbry : ''}#{ed}，消防负责人是#{bg}${this.selDw && this.selDw.xfaqzrrmc ? this.selDw.xfaqzrrmc : ''}#{ed}。`;
      this.$refs.NplDialog.setText(this.selQuest, answer.replace(/#{bg}/g, '<span class="highLight">').replace(/#{ed}/g, '</span>'));
      this.$parent.playVoice(answer.replace(/#{bg}/g, '').replace(/#{ed}/g, '')); // 播报语音
      this.updateGraphSb();
      this.updateGraphJrjb();
    });

    this.initGraphYzjb();
    this.updateGraphYzjb();

    // 图表自适应宽度
    const gRize = function () {
      this.graphSb.resize();
      this.graphJrjb.resize();
      this.graphYzjb.resize();
    };
    window.addEventListener('resize', gRize.bind(this), false);
  },
  destroyed () {
    this.graphSb.dispose();
    this.graphJrjb.dispose();
    this.graphYzjb.dispose();
  }
};
</script>

<style scoped lang="scss">
  @import './npl.scss';

  .npl-model {
    float: left;
    box-sizing: border-box;
    width: 19%;
    height: 100%;
    padding-right: 3%;
    text-align: right;
  }

  .npl-graph {
    float: left;
    box-sizing: border-box;
    height: 100%;
    margin: 0 5px;

    &-left{
      width: 22%;
      padding: 25px 25px 20px 30px;
      border: solid 1px #2667ae;
      background-image: linear-gradient(269deg, rgba(14, 33, 70, 0.24) 0%, rgba(12, 29, 63, 0.64) 10%, rgba(0, 9, 28, 0.72) 99%);
    }

    &-center{
      width: 28%;
    }

    &-right{
      width: 22%;
      padding: 25px 25px 20px 30px;
      border: solid 1px #2667ae;
      background-image: linear-gradient(269deg, rgba(14, 33, 70, 0.24) 0%, rgba(12, 29, 63, 0.64) 10%, rgba(0, 9, 28, 0.72) 99%);
    }
  }

</style>
