/* eslint-disable no-useless-escape */
/*
 * @Author: DQL
 * @Date: 2020-03-09 17:47:35
 * @LastEditors: DQL
 * @LastEditTime: 2021-05-06 17:57:10
 * @Description: file content
 */
const banner = `
========================================================================
  _   _   __  __     
 | | | | |  \/  |    
 | |_| | | |\/| |  
 |  _  | | |  | |  _        ------------------------------------------
 |_| |_| |_|  |_| (_)       |   static resource kestrel-seed(后台管理系统) |
                            ------------------------------------------
 Copyright ©2007-2020 鸿蒙科技, All Rights Reserved
=========================================================================
`;

console.log(banner);
