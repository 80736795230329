var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"el-progress",class:[
    'el-progress--' + _vm.type,
    _vm.status ? 'is-' + _vm.status : '',
    {
      'el-progress--without-text': !_vm.showText,
      'el-progress--text-inside': _vm.textInside,
    }
  ],attrs:{"role":"progressbar","aria-valuenow":_vm.percentage,"aria-valuemin":"0","aria-valuemax":"100"}},[(_vm.showTitle)?_c('div',{staticClass:"progress-title"},[_vm._v(" "+_vm._s(_vm.progressTitle)+" ")]):_vm._e(),(_vm.type === 'line')?_c('div',{staticClass:"el-progress-bar"},[_c('div',{staticClass:"el-progress-bar__outer",style:({height: _vm.strokeWidth + 'px'})},[_c('div',{staticClass:"el-progress-bar__inner",style:(_vm.barStyle)},[(_vm.showText && _vm.textInside)?_c('div',{staticClass:"el-progress-bar__innerText"},[_vm._v(" "+_vm._s(_vm.showStatusText ? _vm.statusText : _vm.percentage+"%")+" ")]):_vm._e()])])]):_c('div',{staticClass:"el-progress-circle",style:({height: _vm.width + 'px', width: _vm.width + 'px'})},[_c('svg',{attrs:{"viewBox":"0 0 100 100"}},[_c('path',{staticClass:"el-progress-circle__track",attrs:{"d":_vm.trackPath,"stroke":"#e5e9f2","stroke-width":_vm.relativeStrokeWidth,"fill":"none"}}),_c('path',{staticClass:"el-progress-circle__path",style:(_vm.circlePathStyle),attrs:{"d":_vm.trackPath,"stroke-linecap":"round","stroke":_vm.stroke,"stroke-width":_vm.relativeStrokeWidth,"fill":"none"}})])]),(_vm.showText && !_vm.textInside)?_c('div',{staticClass:"el-progress__text",style:({fontSize: _vm.progressTextSize + 'px'})},[(!_vm.status)?[_vm._v(" "+_vm._s(_vm.statusText ? _vm.statusText : _vm.percentage+"%")+" ")]:_c('i',{class:_vm.iconClass})],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }