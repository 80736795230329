/*
 * @Author: DQL
 * @Date: 2020-03-05 17:12:35
 * @LastEditors: zxf
 * @LastEditTime: 2023-09-20 17:21:50
 * @Description: file content
 */
import Vue from 'vue';
import VueRouter from 'vue-router';

import { HMStoreService } from 'kestrel-core-ui';

// import store from './store';
import config from './config';
// import { getSubject, systemPreloadInfo } from '@/features/feature.common.service.js';
import { systemPreloadInfo } from '@/features/feature.common.service.js';

import layout from '@/layout.vue';

/* =============================================
   业务功能路由配置文件引入 Start
  *
  *
*/
import virtualRoutes from '@/features/virtual/virtual.routes.js';
import yysbRoutes from '@/features/yysb/yysb.routes.js';
import monitorRoutes from '@/features/monitor/monitor.routes.js';
import cadRoutes from '@/features/cad/cad.routes.js';
import pptRoutes from './features/ppt/ppt.routes';
/* 业务功能路由配置文件引入 End */

/* =============================================
   业务功能路由配置写入 Start
  *
  *
*/

const FEATURES_ROUTES = [virtualRoutes, yysbRoutes, monitorRoutes, cadRoutes, pptRoutes];

/* 业务功能路由配置写入 End */

/* =============================================
   首页路由配置start
  *
  *
*/

const home = { template: '<div>home首页</div>' };
// 系统首页路由
const HOME_ROUTER_PATH = '/monitor';

/* 首页路由配置end */

Vue.use(VueRouter);

/* 重写VueRouter的push方法，解决由于版本问题出现的控制台错误输出
   [NavigationDuplicated {_name: "NavigationDuplicated", name: "NavigationDuplicated"}]
   Vue-router在3.1之后把$router.push()方法改为了Promise。所以假如没有回调函数，错误信息就会交给全局的路由错误处理，因此就会报上述的错误。
*/
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location) {
  return routerPush.call(this, location).catch(error => error);
};

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/features/login/login.vue')
  },
  {
    path: '/switchSubject',
    name: 'switchSubject',
    component: () => import(/* webpackChunkName: "switchSubject" */ '@/features/switchSubject/switchSubject.vue')
  },
  {
    path: '/',
    component: layout,
    redirect: HOME_ROUTER_PATH,
    beforeEnter: (to, from, next) => {
      // 进入根目录前检查是否登录
      const token = window.CookieUtil.get(config.localTokenName) || false;
      if (token) {
        systemPreloadInfo()
          .then(rsp => {
            /* 用户信息处理 Start */

            const userinfo = rsp[0].data;
            HMStoreService.updateUserinfo(userinfo);

            /* 用户信息处理 End */
            next();
          });
      } else {
        next('/login');
      }
    },
    children: [
      {
        path: 'home',
        name: 'home',
        component: home,
        meta: {
          navtabTitle: '首页',
          isCache: true,
          ignoreDiff: [],
          isIframe: false
        }
      },
      // iframe页面路由
      {
        path: 'iframePage',
        name: 'iframePage',
        component: { template: '<div></div>' },
        meta: {
          isCache: true,
          isIframe: true
        }
      },
      ...FEATURES_ROUTES
    ]
  }
];

const router = new VueRouter({
  routes
});

export default router;
