/*
 * @Author: DQL
 * @Date: 2020-03-18 09:56:19
 * @LastEditors: cxb chenxiaobiao@hongmeng-info.com
 * @LastEditTime: 2022-11-30 16:59:32
 * @Description: 机构部门路由配置
 */

/* 路由配置说明
  name: 'router-name' // 设定路由的名字，要求唯一性
  webpackChunkName: "sysMenu" // 设置打包模块，要求名称与name一致，唯一性
  meta: {
    navtabTitle: '' // 设置该路由navtabTitle为stringf或者function
    isCache: true  // 设置页面需要缓存，再次打开页面不刷新数据，默认false，设为true缓存页面
    ignoreDiff: [] // 设置判断页面是否相同需忽略的参数，判断处理时默认忽略isRefresh参数，因此isRefresh不需在此设置
    isSecondary: true  // 页面为父页面的一个小页面，不需要展示在tab上作为一个tab页面展示，设置为true，默认false
    isIframe: false // 是否为iframe页面
    menuElements: [ // 路由页面功能元素配置，页面基础的查询实现必须配置
      { elementCode: '**_search', elementName: '查询功能元素' }, // elementCode编码规则：路由name_功能名称（功能名称通用推荐：查询search、新增add、修改edit、更新update、删除del、导出export、下载download）
    ]
  }
*/

const cadRoutes = {
  path: 'cad',
  name: 'cad',
  component: { template: '<router-view></router-view>' },
  children: [
    {
      path: 'DxfViewer',
      name: 'DxfViewer',
      component: () => import(/* webpackChunkName: "DxfViewer" */ '@/features/cad/DxfViewer/index.vue'),
      meta: {
        navtabTitle: 'DxfViewer',
        isCache: true,
        ignoreDiff: [],
        menuElements: []
      }
    },
    {
      path: 'wzdx',
      name: 'wzdx',
      component: () => import(/* webpackChunkName: "wzdx" */ '@/features/cad/wzdx/index.vue'),
      meta: {
        navtabTitle: 'DxfViewer',
        isCache: true,
        ignoreDiff: [],
        menuElements: []
      }
    }
  ]
};

export default cadRoutes;
