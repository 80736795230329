/*
 * @Author: DYM
 * @Date: 2021-03-03 15:38:15
 * @LastEditors: zxf
 * @LastEditTime: 2023-03-23 13:05:58
 * @Description: file content
 */
import Vue from 'vue';
import ReProgress from './reProgress.vue';
import NplDwxf from '@/features/virtual/npl/npl-dwxf.vue';
import NplDw from '@/features/virtual/npl/npl-dw.vue';
import NplZb from '@/features/virtual/npl/npl-zb.vue';
import NplXfswg from '@/features/virtual/npl/npl-xfswg.vue';
import NplXfxj from '@/features/virtual/npl/npl-xfxj.vue';
import NplXfjc from '@/features/virtual/npl/npl-xfjc.vue';
import NplXfby from '@/features/virtual/npl/npl-xfby.vue';

Vue.component('ReProgress', ReProgress);
Vue.component('NplDwxf', NplDwxf);
Vue.component('NplDw', NplDw);
Vue.component('NplZb', NplZb);
Vue.component('NplXfswg', NplXfswg);
Vue.component('NplXfxj', NplXfxj);
Vue.component('NplXfjc', NplXfjc);
Vue.component('NplXfby', NplXfby);
