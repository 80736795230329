<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="npl-dialog">
    <div class="npl-dialog-title">
      <img
        src="/3DModel/npl/wechat.png"
        style="width: 30px;"
      >
      {{ title }}
    </div>
    <div
      class="npl-dialog-content"
      v-html="content"
    />
  </div>
</template>

<script>
export default {
  name: 'NplDialog',
  data () {
    return {
      title: null,
      content: null
    };
  },
  methods: {
    setText (title, content) {
      this.title = title;
      this.content = content;
    }
  }
};
</script>

<style lang="scss">
.npl-dialog {
  height: 207px;
  text-align: left;
  background: url("/3DModel/npl/dialog.png") no-repeat;
  background-size: 100% 100%;
}

.npl-dialog-title {
  width: 95%;
  height: 29px;
  overflow: hidden;
  padding: 10px;
  font-family: SimHei;
  font-size: 16px;
  font-stretch: normal;
  // font-weight: bold;
  line-height: 16px;
  letter-spacing: 0;
  white-space: nowrap;
  color: #08ffff;
  text-overflow: ellipsis;
}

.npl-dialog-content{
  padding: 0 35px 0 15px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  font-stretch: normal;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0;
  color: #e9e9e9;

  .highLight {
    color: #08f7f7;
  }
}
</style>
