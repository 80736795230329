/*
 * @Author: zxf
 * @Date: 2022-09-05 11:22:28
 * @LastEditors: cxb chenxiaobiao@hongmeng-info.com
 * @LastEditTime: 2023-03-02 16:20:33
 * @Description: file content
 */

import axios from 'axios';
import config from '@/config';

const { iffApi } = config.ctx;

export const zhxfService = {
  // 查询水位信息
  queryGwmdyl: (lwdw, ssbm) => { return axios.get(`${iffApi}/dp/xfsc/${lwdw}/${ssbm}?time=${new Date().getTime()}`); },

  // 查询管网压力
  queryGwyl: (lwdw, ssbm) => { return axios.get(`${iffApi}/dp/gwyl/${lwdw}/${ssbm}`); },

  // 查询部件
  queryBjxx: (params) => { return axios.get(`${iffApi}/bjxx/zt`, { params: params }); }

};
