<!--
 * @Author: DQL
 * @Date: 2020-03-06 11:15:00
 * @LastEditors: DQL
 * @LastEditTime: 2022-10-31 10:21:47
 * @Description: file content
 -->
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import config from '@/config.js';
import packageconfig from '../package.json';
import EventBus from './event-bus';

export default {
  name: 'App',
  data () {
    return {
    };
  },
  beforeCreate () {
    document.title = config.app.name;
    if (config.isFirstNavtab) {
      this.$HMStoreService.setFirstNavTab(config.firstNavtab);
    }
  },
  mounted () {
    this.version = packageconfig.version;
    console.log('当前版本号' + this.version);
    // 监听EventBus触发事件
    /* 调用方式：
      1、 EventBus.$emit('add', params);
      2、window.EventBus.$emit('add', params);
    */
    EventBus.$on('add', param => {
      console.log('EventBus事件触发，入参：', param);
    });
  }
};
</script>
