/*
 * @Author: DQL
 * @Date: 2022-02-14 14:48:14
 * @LastEditors: cxb chenxiaobiao@hongmeng-info.com
 * @LastEditTime: 2023-11-20 18:03:30
 * @Description: file content
 */
import { Message } from 'element-ui';
import qs from 'qs';
import { HMCoreStoreService } from 'kestrel-core-ui'; // core-ui库
import EventBus from './event-bus';

const HMAxiosInterceptor = (_axios, _router, _config = {
  serverHeaderTokenName: 'hmbase-auth-token',
  localTokenName: 'hmbase-auth-token',
  ctxPrefix: null
}) => {
  const defaultConfig = {
    serverHeaderTokenName: _config.serverHeaderTokenName || 'hmbase-auth-token',
    localTokenName: _config.localTokenName || 'hmbase-auth-token'
  };
  if (_config.ctxPrefix) {
    _axios.defaults.baseURL = _config.ctxPrefix;
  }
  _axios.defaults.paramsSerializer = function (params) {
    return qs.stringify(params, { arrayFormat: 'repeat' });
  };

  _axios.interceptors.request.use(
    function (request) {
      // 自动给 get 请求增加时间戳
      if (request.method === 'get') {
        request.params = request.params || {};
        request.params._ = new Date().getTime();
      }
      // 访问本地资源去除服务前缀地址
      if (request.url.indexOf('./static/') > -1) {
        request.baseURL = '';
      }
      const localTokenName = defaultConfig.localTokenName;
      const serverHeaderTokenName = defaultConfig.serverHeaderTokenName;
      const token = window.CookieUtil.get(localTokenName);

      request.headers[serverHeaderTokenName] = token;
      return request;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  _axios.interceptors.response.use(
    function (response) {
      // if (response.data && response.data.code) {
      //   switch (response.data.code) {
      //     default:
      //       /* Message.error({
      //         message: response.data.msg + '',
      //         type: 'error'
      //       }); */
      //       MessageBox.alert(response.data.msg, '提示', {
      //         confirmButtonText: '确定',
      //         type: 'error',
      //         callback: action => {
      //         }
      //       });
      //       return response;
      //   }
      // }
      return response;
    },
    function (error) {
      if (!error || !error.response) return Promise.reject(error);
      const status = error.response.status;
      switch (status) {
        case 401: // 会话超时
          HMCoreStoreService.removeUserinfo();
          window.CookieUtil.unset(defaultConfig.localTokenName);
          HMCoreStoreService.resetNavTab();
          if (_router.currentRoute.path.indexOf('login') <= 0) {
            Message.error({
              message: error.response.data.errorMsg,
              type: 'error'
            });
            _router.push('/login');
          }
          break;
        case 4012: // 用户主身份未选择
          Message.error({
            message: error.response.data.errorMsg,
            type: 'error'
          });
          return Promise.reject(error.response);
          // break;
        case 404:
          EventBus.$emit('apiMessage', '未找到接口服务');
          // Message.error({
          //   message: '未找到接口服务',
          //   type: 'error'
          // });
          return Promise.reject(error.response);
          // break;
        case 504:
          EventBus.$emit('apiMessage', '系统处理超时，请稍后再试，或联系系统管理员进行反馈！');
          // Message.error({
          //   message: '系统处理超时，请稍后再试，或联系系统管理员进行反馈！',
          //   type: 'error'
          // });
          return Promise.reject(error.response);
          // break;
        default: // 其它异常 3000 +
          /* Message.error({
            message: error.response.data.errorMsg + '',
            type: 'error'
          }); */
          if (error.response.config.responseType === 'blob') {
            // 将blob转为json
            const reader = new FileReader();
            const blob = new Blob([error.response.data]);
            reader.readAsText(blob, 'utf-8');
            reader.onload = function () {
              const parseObj = JSON.parse(reader.result);
              Message.error({
                message: parseObj.errorMsg,
                type: 'error'
              });
            };
          } else if (error.response.data) {
            if (status === 400 && error.config.method === 'post' && error.config.url.indexOf('/security/login') > 0) { // 用户账号密码错误不弹出处理
            } else {
              EventBus.$emit('apiMessage', '系统连接异常');
              // Message.error({
              //   message: error.response.data.errorMsg,
              //   type: 'error'
              // });
            }
          } else {
            Message.error({
              message: status + error.response.statusText,
              type: 'error'
            });
          }

          return Promise.reject(error.response);
      }
      // Do something with response error
      return Promise.reject(error);
    }
  );
};

export default HMAxiosInterceptor;
