<template>
  <div style="height: 100%;">
    <div class="npl-zb-model">
      <img
        style="height: 100%;"
        src="/3DModel/npl/women.png"
      >
    </div>

    <!-- 中间 -->
    <div class="npl-zb npl-zb-center">
      <npl-dialog ref="NplDialog" />
      <div class="npl-schart__header u-font-PFSC">
        <div class="npl-schart__title">
          值班人员
        </div>
      </div>
      <div class="c-schart__body">
        <div class="npl-study">
          <div class="npl-study__img">
            <img
              src="/images/screen/user.png"
              alt="黄光健"
            >
          </div>
          <ul class="npl-study__body">
            <li class="npl-study__item">
              <span class="npl-study__label">姓名：</span>
              <span class="npl-study__content">{{ zb.name }}</span>
            </li>
            <li class="npl-study__item">
              <span class="npl-study__label">手  机 号：</span>
              <span class="npl-study__content">{{ zb.tel }}</span>
            </li>
            <li class="npl-study__item">
              <span class="npl-study__label">证    书：</span>
              <span class="npl-study__content">{{ zb.zyzg }}</span>
            </li>
            <li class="npl-study__item">
              <span class="npl-study__label">证书编号：</span>
              <span class="npl-study__content">{{ zb.zsbh }}</span>
            </li>
            <li class="npl-study__item">
              <span class="npl-study__label">在岗时间：</span>
              <span class="npl-study__content">{{ zb.zgsj?zb.zgsj:0 }}小时</span>
            </li>
            <!-- <li class="npl-study__item">
              <span class="npl-study__label">今日查岗次数：</span>
              <span class="npl-study__content">{{ zb.cgcs }}</span>
            </li>
            <li class="npl-study__item">
              <span class="npl-study__label">最新查岗时间：</span>
              <span class="npl-study__content">{{ zb.fqsj }}</span>
            </li>
            <li class="npl-study__item">
              <span class="npl-study__label">最新应岗状态：</span>
              <span class="npl-study__content">{{ zb.ygzt }}</span>
            </li>
            <li class="npl-study__item">
              <span class="npl-study__label">最新应岗时间：</span>
              <span class="npl-study__content">{{ zb.tgsj }}</span>
            </li> -->
          </ul>
        </div>
      </div>
      <div
        v-show="zb.zjdzbUrl"
        class="npl-study"
      >
        <img
          :src="zb.zjdzbUrl"
          class="npl-study__zs"
        >
      </div>
    </div>

    <!-- 右边 -->
    <div class="npl-zb npl-zb-right">
      <div
        class="npl-echart"
        style="height: 47%;"
      >
        <div class="npl-schart__header u-font-PFSC">
          <div class="npl-schart__title">
            值班台账
          </div>
        </div>
        <div class="c-schart__body">
          <table class="npl-table">
            <tr class="npl-table__head">
              <th>值班人员</th>
              <th>签到时间</th>
              <th>签退时间</th>
              <th>联系电话</th>
              <th>值班电话</th>
            </tr>
            <tr
              v-for="item in zbList"
              :key="item.id"
              class="npl-table__body"
            >
              <td>{{ item.zbrymc }}</td>
              <td>{{ item.zbsjq |date('yyyy-MM-dd HH:mm:ss') }}</td>
              <td>{{ item.zbsjz |date('yyyy-MM-dd HH:mm:ss') }}</td>
              <td>{{ item.lxdh }}</td>
              <td>{{ item.kzsdh }}</td>
            </tr>
          </table>
        </div>
      </div>

      <div
        class="npl-echart"
        style="height: 50%;"
      >
        <div class="npl-schart__header u-font-PFSC">
          <div class="npl-schart__title">
            查岗台账
          </div>
        </div>
        <div class="npl-schart__body">
          <table class="npl-table">
            <tr class="npl-table__head">
              <th>查岗人员</th>
              <th>查岗时间</th>
              <th>值班人员</th>
              <th>回应时间</th>
              <th>回应状态</th>
            </tr>
            <tr
              v-for="item in cgList"
              :key="item.id"
              class="npl-table__body"
            >
              <td>{{ item.fqrMc }}</td>
              <td>{{ item.fqsj|date('yyyy-MM-dd HH:mm:ss') }}</td>
              <td>{{ item.zbrymc }}</td>
              <td>{{ item.hysj|date('yyyy-MM-dd HH:mm:ss') }}</td>
              <td>{{ item.ydzt === 'Y'?'已应答':item.ydzt === 'D'? '待应答':'应答异常' }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NplDialog from './npl-dialog.vue';
import { virtualService } from '../virtual.service';
import { iffService } from './npl.service';

import config from '@/config';
const { iffApi } = config.ctx;

export default {
  name: 'NplZb',
  components: {
    NplDialog
  },
  props: {
    selDw: {
      type: Object,
      default: () => { return null; }
    },
    selQuest: {
      type: String,
      default: () => { return null; }
    }
  },
  data () {
    return {
      zb: {
        // name: '黄光健',
        // tel: '13288679012',
        // zyzg: '四级消防设施操作员',
        // zsbh: '1936003008400373',
        // zgsj: '6小时24分'
      },
      zbList: [
        // {
        //   zbrymc: '黄光健',
        //   zbsjq: '2022-12-05 09:00:10',
        //   zbsjz: '2022-12-05 09:00:10',
        //   lxdh: '13800138000',
        //   kzsdh: '400-001-8831'
        // },
        // {
        //   zbrymc: '黄光健',
        //   zbsjq: '2022-12-05 09:00:10',
        //   zbsjz: '2022-12-05 09:00:10',
        //   lxdh: '13800138000',
        //   kzsdh: '400-001-8831'
        // }
      ],
      cgList: [
        // {
        //   fqrMc: '超级管理员',
        //   fqsj: '2022-12-05 12:06:10',
        //   zbrymc: '黄光健',
        //   hysj: '2022-12-05 12:08:10',
        //   ydzt: 'D'
        // },
        // {
        //   fqrMc: '超级管理员',
        //   fqsj: '2022-12-05 12:06:10',
        //   zbrymc: '黄光健',
        //   hysj: '2022-12-05 12:08:10',
        //   ydzt: 'Y'
        // }
      ]
    };
  },
  methods: {
    updateZbList () {
      iffService.queryZbjl({
        lwdw: this.selDw.code,
        pageSize: 5,
        pageNo: 1
      }).then(resp => {
        this.zbList = resp.data.rows;
      });
    },
    updateCgList () {
      iffService.queryZbcg({
        ssdw: this.selDw.code,
        pageSize: 5,
        pageNo: 1
      }).then(resp => {
        this.cgList = resp.data.rows;
      });
    }
  },
  mounted () {
    virtualService.queryZbry(this.selDw.code)
      .then(resp => {
        this.zb = resp.data[0] ? resp.data[0] : {};
        if (this.zb.zjdzb) {
          this.zb.zjdzbUrl = `${iffApi}/common/image/${this.zb.zjdzb}`;
        }
        const answer = `当前值班人员#{bg}${this.zb.name ? this.zb.name : ''}#{ed}，${this.zb.zyzg ? this.zb.zyzg + '，' : ''}在岗时间#{bg}${this.zb.zgsj ? this.zb.zgsj : ''}#{ed}小时。`;
        this.$refs.NplDialog.setText(this.selQuest, answer.replace(/#{bg}/g, '<span class="highLight">').replace(/#{ed}/g, '</span>'));
        this.$parent.playVoice(answer.replace(/#{bg}/g, '').replace(/#{ed}/g, '')); // 播报语音
      });

    this.updateZbList();
    this.updateCgList();
  }
};
</script>

<style scoped lang="scss">
@import './npl.scss';

.npl-zb-model {
  float: left;
  box-sizing: border-box;
  width: 30%;
  height: 100%;
  padding-right: 3%;
  text-align: right;
}

.npl-zb {
  float: left;
  box-sizing: border-box;
  height: 100%;
  margin: 0 5px;
  text-align: left;

  &-center{
    width: 22%;
    padding: 25px 25px 20px 30px;
    border: solid 1px #2667ae;
    background-image: linear-gradient(269deg, rgba(14, 33, 70, 0.24) 0%, rgba(12, 29, 63, 0.64) 10%, rgba(0, 9, 28, 0.72) 99%);
  }

  &-right{
    width: 38%;
  }
}
</style>
